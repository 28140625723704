import format from "date-fns-tz/format"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useQuery } from "react-query"

import Badge from "src/components/Badge"

import { queryUnviewedScheduleAppointmentCounts } from "src/api/DryStack"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const LaunchNotificationBadge = ({ initialLaunchCount }) => {
  const marinaSlug = getCurrentMarinaSlug()
  const [unviewedLaunchCount, setUnviewedLaunchCount] =
    useState(initialLaunchCount)

  useQuery(
    ["unviewedScheduleAppointmentsCount", marinaSlug],
    () => {
      const currentDate = format(new Date(), "yyyy-MM-dd")
      return queryUnviewedScheduleAppointmentCounts(marinaSlug, currentDate)
    },
    {
      retry: false,
      refetchInterval: 60 * 1000,
      initialData: initialLaunchCount,
      staleTime: 60 * 1000,
      onSuccess: (data) => {
        const newCount = data.dailyUnviewedCount + data.allTimeUnviewedCount
        setUnviewedLaunchCount(Math.max(newCount, unviewedLaunchCount))
      },
    }
  )

  if (unviewedLaunchCount > 0) {
    return <Badge text={unviewedLaunchCount} color="notification" />
  }
}

LaunchNotificationBadge.propTypes = {
  initialLaunchCount: PropTypes.number.isRequired,
}

export default LaunchNotificationBadge
