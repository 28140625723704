import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { useMutation, useQueryClient } from "react-query"

import { removeFavorite } from "src/api/Account/Favorites"

import useHover from "src/hooks/use_hover"
import { useTracker } from "src/hooks/use_tracker"

const FavoriteCard = ({ favorite }) => {
  const [hoverRef, isHovered] = useHover()
  const tracker = useTracker()

  const queryClient = useQueryClient()

  const { mutate } = useMutation((marinaSlug) => removeFavorite(marinaSlug), {
    onSuccess: () => {
      queryClient.invalidateQueries("favorites")
    },
  })

  const handleFavoriteClick = () => {
    tracker.trackEvent("favorites:view_marina_pressed")
  }

  const handleRemoveFavorite = (event, favorite) => {
    event.stopPropagation()
    event.preventDefault()
    tracker.trackEvent("favorites:remove_marina_pressed")
    mutate(favorite.marinaSlug)
  }

  return (
    <div className="col-span-1" key={favorite.id} ref={hoverRef}>
      <a
        className="relative flex h-full cursor-pointer flex-col rounded border bg-white text-gray-900 no-underline shadow-md"
        href={favorite.viewUrl}
        onClick={handleFavoriteClick}
      >
        <div
          className={classNames(
            "relative flex justify-center rounded-t bg-gray-300",
            { "brightness-90": isHovered }
          )}
          style={{ paddingTop: "75%" }}
        >
          <img
            className="absolute top-0 h-full w-full rounded-t"
            src={favorite.marinaImageUrl}
            alt={favorite.marinaName}
          />
        </div>
        <div className="flex w-full justify-between bg-white p-4">
          <div className="flex flex-col">
            <span className="w-56 overflow-hidden truncate font-semibold">
              {favorite.marinaName}
            </span>
            <span className="text-gray-600">{favorite.marinaLocation}</span>
            {favorite.marinaReviewCount > 0 && (
              <div className="mt-4 flex items-center space-x-1">
                <i className="icon icon-star text-yellow-600" />
                <span>{favorite.marinaRating}</span>
                <span>({favorite.marinaReviewCount})</span>
              </div>
            )}
          </div>
        </div>
        <div
          className="absolute right-2 top-2"
          onClick={(event) => handleRemoveFavorite(event, favorite)}
        >
          <div className="group relative" data-testid="heart-icon">
            <i className="icon icon-heart-bts absolute right-2 text-lg text-red-600 group-hover:text-red-700" />
            <i className="icon icon-heart-btm absolute right-2 text-lg text-white" />
          </div>
        </div>
      </a>
    </div>
  )
}

FavoriteCard.propTypes = {
  favorite: PropTypes.shape({
    id: PropTypes.number,
    marinaLocation: PropTypes.string,
    marinaName: PropTypes.string,
    marinaImageUrl: PropTypes.string,
    marinaRating: PropTypes.number,
    marinaReviewCount: PropTypes.number,
    marinaSlug: PropTypes.string,
    viewUrl: PropTypes.string,
  }),
}

export default FavoriteCard
