import PropTypes from "prop-types"
import React from "react"

import Modal from "src/components/Modal"

const DetailsModal = ({ isOpen, setIsOpen, dockwaDeal }) => {
  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Modal.Header title={dockwaDeal.readableDiscount} />
      <Modal.Body>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-1">
            <span className="font-semibold">Discount applies to</span>
            <span>{dockwaDeal.validRangeReadable}</span>
          </div>
          <div className="flex flex-col space-y-1">
            <span className="font-semibold">Description</span>
            <span>{dockwaDeal.description}</span>
          </div>
          {dockwaDeal.expiresAt && (
            <div className="flex flex-col space-y-1">
              <span className="font-semibold">Expiration</span>
              <span>Book before {dockwaDeal.expiresAt}</span>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

DetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  dockwaDeal: PropTypes.shape({
    id: PropTypes.number.isRequired,
    discount: PropTypes.number.isRequired,
    category: PropTypes.string.isRequired,
    expiresAt: PropTypes.string,
    validRangeReadable: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    readableDiscount: PropTypes.string.isRequired,
    expiresSoon: PropTypes.bool.isRequired,
  }),
}

export default DetailsModal
