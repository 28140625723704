export const DUE_DATE_VALUES = [
  ...[...Array(28).keys()].map((i) => ({
    value: i + 1,
    display: i + 1,
  })),
  {
    value: 32,
    display: "End of month",
  },
]

export const RESERVATION_START_DAY_KEY = "reservation_start_day"
export const PERCENT_OF_STORAGE_PRICING_STRUCTURE =
  "percent_of_reservation_sale"

export const INSTALLMENT_SCHEDULE_NAME_MAPPING = {
  upfront: "Upfront",
  check_in: "Check-in",
  check_out: "Check-out",
  deposit_one_night: "Deposit (one night)",
  deposit_quarter: "Deposit (25%)",
  deposit_half: "Deposit (50%)",
  monthly: "Monthly",
}

export const ITEM_PRICE_PER_UNIT_PRECISION = {
  cents: 2,
  hundredths_of_cents: 4,
}

export const PRICE_TYPE_MAPPING = {
  per_day: "Per night",
  per_stay: "Per reservation",
}

export const DEFAULT_RECURRING_PRICING_STRUCTURE = "per_month"
export const DEFAULT_ONE_TIME_PRICING_STRUCTURE = "per_quantity"

export const ADD_NEW_CARD_ID = "add"
