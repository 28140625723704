import { mutateApi, queryApi } from "../../utils/api/ApiMethods"

export const queryGeneralLedgerCodes = ({ marinaSlug }) => {
  return queryApi(`/manage/${marinaSlug}/settings/general_ledger_codes`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}

export const queryAccountingExports = ({ marinaSlug }) => {
  return queryApi(`/manage/${marinaSlug}/accounting/exports`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}

export const queryAccountingServiceConfigurations = ({ marinaSlug }) => {
  return queryApi(`/manage/${marinaSlug}/accounting_service_configurations`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}

export const queryAccountingServiceConfiguration = ({
  marinaSlug,
  serviceId,
}) => {
  return queryApi(
    `/manage/${marinaSlug}/accounting_service_configurations/${serviceId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
}

export const queryMappings = ({ marinaSlug, collectionType }) => {
  return queryApi(
    `/manage/${marinaSlug}/accounting/general_ledger_codes/mappings?type=${collectionType}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
}

export const queryGeneralLedgerCodeSyncStatus = (marinaSlug) => {
  return queryApi(
    `/manage/${marinaSlug}/accounting/general_ledger_codes/sync/status`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
}

export const syncGeneralLedgerCodes = (marinaSlug) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/accounting/general_ledger_codes/sync`,
    method: "POST",
  })
}

export const updateGeneralLedgerCode = (marinaSlug, id, data) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/settings/general_ledger_codes/${id}`,
    method: "PATCH",
    data,
  })
}

export const updateAllGeneralLedgerCodes = (marinaSlug, data) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/settings/general_ledger_codes/update_all`,
    method: "POST",
    data,
  })
}

export const updateGeneralLedgerCodeMapping = (marinaSlug, data) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/accounting/general_ledger_codes/mappings`,
    method: "PATCH",
    data,
  })
}

export const createExport = ({ marinaSlug, data }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/accounting/exports`,
    method: "POST",
    data: {
      export: data,
    },
  })
}

export const sinceLastExport = ({ marinaSlug }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/accounting/exports/since_last_export`,
    method: "POST",
  })
}

export const retryExport = ({ marinaSlug, exportId }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/accounting/exports/${exportId}/retry`,
    method: "POST",
  })
}
