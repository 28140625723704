import PropTypes from "prop-types"
import React from "react"
import { groupByRecurringProductSaleId } from "src/main/Billing/helpers"

import ReloadableWidget from "src/components/ReloadableWidget"
import Table from "src/components/Table/index"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

import ItemRow from "./ItemRow"
import RecurringItemRow from "./RecurringItemRow"
import { getEffectiveBalance } from "./helpers"

const ItemsTable = ({
  items,
  isLoading,
  isError,
  onViewingItem,
  onRemovingItem,
  onEditingRecurringProductSale,
  contractQuoteId,
  reservationCheckOutDate,
  saleId,
  editable = true,
}) => {
  const totalBalance = () =>
    (items || []).reduce((acc, item) => acc + getEffectiveBalance(item), 0)

  const totalPrice = () =>
    (items || []).reduce((acc, item) => acc + item.net_amount, 0)

  const itemsByRecurringProductSaleId = () => {
    const groupedData = items.reduce(groupByRecurringProductSaleId, {})
    return Object.entries(groupedData)
  }

  const renderItemRows = ([recurringItemId, items]) => {
    if (recurringItemId === "null") {
      return items.map((item) => (
        <ItemRow
          key={item.id}
          item={item}
          onViewingItem={onViewingItem}
          onRemovingItem={onRemovingItem}
          contractQuoteId={contractQuoteId}
          reservationCheckOutDate={reservationCheckOutDate}
          editable={editable}
        />
      ))
    } else {
      return (
        <RecurringItemRow
          key={recurringItemId}
          items={items}
          onViewingItem={onViewingItem}
          onRemovingItem={onRemovingItem}
          onEditingRecurringProductSale={onEditingRecurringProductSale}
          editable={editable}
        />
      )
    }
  }

  const renderItems = () =>
    items.length > 0 ? (
      itemsByRecurringProductSaleId().map(renderItemRows)
    ) : (
      <Table.Row>
        <td className="px-4" colSpan="5" align="center">
          No products / services match the current filter
        </td>
      </Table.Row>
    )

  return (
    <Table autoColumnWidth>
      <Table.Head>
        <Table.Head.Row>
          <Table.Head.Cell columnWidth="24%">Name</Table.Head.Cell>
          <Table.Head.Cell columnWidth="22%">Billing Dates</Table.Head.Cell>
          <Table.Head.Cell columnWidth="18%">Status</Table.Head.Cell>
          <Table.Head.Cell columnWidth="18%">Price</Table.Head.Cell>
          <Table.Head.Cell columnWidth="18%">Balance</Table.Head.Cell>
          <Table.Cell> </Table.Cell>
        </Table.Head.Row>
      </Table.Head>
      <Table.Body isLoading={isLoading && Boolean(items)}>
        {items ? (
          renderItems()
        ) : (
          <Table.Row>
            <Table.Cell>
              <ReloadableWidget
                isLoading={isLoading}
                isError={isError}
                border={false}
              />
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      <Table.Foot>
        <Table.Foot.Row>
          <Table.Foot.Cell>
            <div className="py-2.5">Total{saleId ? "" : " balance"}</div>
          </Table.Foot.Cell>
          <Table.Cell> </Table.Cell>
          <Table.Cell> </Table.Cell>
          <Table.Foot.Cell>
            {saleId ? formattedCentsToDollars(totalPrice()) : ""}
          </Table.Foot.Cell>
          <Table.Foot.Cell>
            <div className="py-2.5">
              {formattedCentsToDollars(totalBalance())}
            </div>
          </Table.Foot.Cell>
          <Table.Cell> </Table.Cell>
        </Table.Foot.Row>
      </Table.Foot>
    </Table>
  )
}

ItemsTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      balance: PropTypes.number.isRequired,
      product_sale: PropTypes.shape({
        recurring_product_sale: PropTypes.shape({
          id: PropTypes.string,
        }),
      }),
    })
  ),
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  onViewingItem: PropTypes.func.isRequired,
  onRemovingItem: PropTypes.func.isRequired,
  onEditingRecurringProductSale: PropTypes.func.isRequired,
  contractQuoteId: PropTypes.number,
  saleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reservationCheckOutDate: PropTypes.string,
  editable: PropTypes.bool,
}

export default ItemsTable
