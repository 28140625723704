import classNames from "classnames"
import { differenceInCalendarDays, format, isDate, subDays } from "date-fns"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useInfiniteQuery, useMutation } from "react-query"
import { getPricePerUnitPrecision } from "src/main/Billing/Items/helpers"

import Divider from "src/components/Divider"
import Form from "src/components/Form"
import Modal from "src/components/Modal"
import ReloadableWidget from "src/components/ReloadableWidget"

import {
  createRecurringProductSale,
  createReservationTxn,
  queryProducts,
} from "src/api/Billing/Items"

import { snakecaseToTitlecase } from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import DueDayDropdown from "../../DueDayDropdown"
import PaymentMethodDropdown from "../../PaymentMethodDropdown"
import {
  DEFAULT_ONE_TIME_PRICING_STRUCTURE,
  DEFAULT_RECURRING_PRICING_STRUCTURE,
} from "../../constants"
import { parseDateValue } from "../../helpers"
import PaymentScheduleDropdown from "../PaymentScheduleDropdown"
import {
  calculateQuantityIncludingMeteredElectric,
  getDueDate,
  getPriceAndTaxAmounts,
  pricePerUnitToDollars,
  toPercentBasedRate,
  toTaxPercent,
} from "../helpers"
import Footer from "./Footer"
import Header from "./Header"
import DateSetFields from "./fields/DateSetFields"
import { ItemPricingFields } from "./fields/ItemPricingFields"
import MeterField from "./fields/MeterField"
import MeterReadingFields from "./fields/MeterReadingFields"
import RecurringEndDateField from "./fields/RecurringEndDateField"
import RecurringStartDateField from "./fields/RecurringStartDateField"

const RESERVATION_EXISTS_PRODUCT_CATEGORIES = [
  "metered_electric",
  "long_term_storage",
]
const NON_RECURRING_PRODUCT_CATEGORIES = [
  "metered_electric",
  "long_term_storage",
]

const PRICING_STRUCTURES_WITH_QUANTITY = [
  DEFAULT_ONE_TIME_PRICING_STRUCTURE,
  DEFAULT_RECURRING_PRICING_STRUCTURE,
]

const RESERVATION_DATES_PRICING_STRUCTURE = "per_day"
const PERCENT_OF_STORAGE_PRICING_STRUCTURE = "percent_of_reservation_sale"

const DEFAULT_PAYMENT_METHODS = { cards: [], default: { id: null } }

const AddItemModal = ({
  ledgerId,
  contractEndDate,
  contractStartDate,
  contractQuoteId,
  reservationId,
  paymentMethods,
  onItemAdded,
  isOpen,
  onClose,
  onSubmit,
  initialValues,
  isMonthToMonth,
  isMonthlyInstallments,
  hasScheduledInvoice,
  reservationCheckInDate,
  reservationCheckOutDate,
  reservationSale,
  m2mPercentBasedItemsEnabled = false,
}) => {
  const bodyRef = useRef()
  const [expandAdvancedOptions, setExpandAdvancedOptions] = useState(false)
  const [paymentScheduleDisabled, setPaymentScheduleDisabled] = useState(false)

  const isReservationView = !!reservationId && !contractQuoteId
  const isUnsignedContractView = !contractQuoteId && !reservationId
  const isSignedContractView = !!contractQuoteId && !!reservationId
  const isContractView = isUnsignedContractView || isSignedContractView
  const canShowPercentOfStorageType = !isReservationView

  const marinaSlug = getCurrentMarinaSlug()
  const excludedProductCategories = reservationId
    ? []
    : RESERVATION_EXISTS_PRODUCT_CATEGORIES

  const {
    isFetching: isFetchingProducts,
    isLoading: isLoadingProducts,
    isError: isErrorProducts,
    data: productsData,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ["products", marinaSlug],
    queryFn: ({ pageParam = 1 }) =>
      queryProducts({
        page: pageParam,
        marinaSlug,
        excludedCategories: excludedProductCategories,
        availability: ["contract_reservation_add_ons"],
      }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length) {
        return pages.length + 1
      }
    },
    refetchOnWindowFocus: false,
  })

  const products = useMemo(
    () => (productsData?.pages || []).flatMap((page) => page),
    [productsData]
  )

  const { cards: paymentCards, default: defaultPaymentMethod } =
    paymentMethods ?? DEFAULT_PAYMENT_METHODS

  const defaultPaymentMethodId = paymentMethods
    ? defaultPaymentMethod?.id || "manual"
    : null

  const defaultPaymentSchedule = hasScheduledInvoice
    ? "add_to_next_payment"
    : "custom_due_date"

  const defaultServiceStart =
    contractStartDate && !reservationId ? contractStartDate : new Date()

  const defaultServiceEnd = () => {
    if (reservationId) {
      return new Date()
    } else if (isMonthToMonth && contractStartDate) {
      return contractStartDate
    } else if (!isMonthToMonth && contractEndDate) {
      return contractEndDate
    } else {
      return new Date()
    }
  }

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    resetField,
    watch,
    setValue,
    clearErrors,
  } = useForm({
    shouldUnregister: true,
    defaultValues: {
      payment_method_id: defaultPaymentMethodId,
      payment_schedule: defaultPaymentSchedule,
      recurring_product: isMonthToMonth || isMonthlyInstallments ? "yes" : "no",
      recurring_end_selection: isMonthToMonth ? "never" : "onDate",
      start_date: new Date(),
      end_date: new Date(),
      monthly_due_date: 1,
      custom_due_date: new Date(),
      ...initialValues,
      txn: {
        product_sale_attributes: {
          pricing_structure: DEFAULT_ONE_TIME_PRICING_STRUCTURE,
          name: null,
          product_id: "",
          price_per_unit: null,
          quantity: 1,
          tax_percent: null,
          service_start_date: defaultServiceStart,
          service_end_date: defaultServiceEnd(),
          external_sale: false,
          ...(paymentMethods ? {} : { request_external_settlement: false }),
          ...(initialValues?.txn?.product_sale_attributes
            ? initialValues.txn.product_sale_attributes
            : {}),
        },
      },
    },
  })

  // Registering txn.product_sale_attributes.name here so it can be set when the product ID input is changed
  register("txn.product_sale_attributes.name")
  register("payment_schedule")
  // when the m2mPercentBasedItemsEnabled FF is enabled we do not have a pricing_structure input on the page,
  // we only have the checkbox for %-based that on change manipulates the pricing_structure value. We must register
  // the field so it can be referenced
  register("txn.product_sale_attributes.pricing_structure")

  const [
    itemWillRecur,
    priceType,
    serviceStartDate,
    serviceEndDate,
    startDate,
    endDate,
    paymentSchedule,
    productId,
    precision,
    recurringEndSelection,
    lastMeterReading,
    perDayDateType,
  ] = watch([
    "recurring_product",
    "txn.product_sale_attributes.pricing_structure",
    "txn.product_sale_attributes.service_start_date",
    "txn.product_sale_attributes.service_end_date",
    "start_date",
    "end_date",
    "payment_schedule",
    "txn.product_sale_attributes.product_id",
    "txn.product_sale_attributes.price_precision",
    "recurring_end_selection",
    "metered_electric.last_meter_reading",
    "perDayDateType",
  ])

  const { mutate: mutateProductSale, isLoading: isLoadingMutateProductSale } =
    useMutation({
      queryKey: ["productSaleTxn", reservationId],
      mutationFn: (data) =>
        createReservationTxn({ marinaSlug, reservationId, data }),
      onSuccess: () => {
        onItemAdded()
        reset()
      },
      onError: () => {
        console.log("createReservationTxn error")
      },
    })

  const {
    mutate: mutateRecurringProductSale,
    isLoading: isLoadingMutateRecurringProductSale,
  } = useMutation({
    queryKey: ["recurringProductSale", contractQuoteId],
    mutationFn: (data) => createRecurringProductSale({ data }),
    onSuccess: () => {
      onItemAdded()
      reset()
    },
    onError: () => {
      console.log("createRecurringProductSale error")
    },
  })

  const submitForm = (data) => {
    isRecurring ? submitRecurringProductSale(data) : submitProductSale(data)
  }

  const submitRecurringProductSale = (data) => {
    const {
      end_date: endDate,
      txn: {
        product_sale_attributes: {
          pricing_structure: pricingStructure,
          quantity,
        },
      },
    } = data
    const isPercentOfReservationSale =
      data.txn.product_sale_attributes.pricing_structure ===
      PERCENT_OF_STORAGE_PRICING_STRUCTURE
    const { originalAmount, taxRate, pricePerUnit } =
      getPriceAndTaxAmounts(data)
    const itemQuantity = isPercentOfReservationSale
      ? toPercentBasedRate(quantity)
      : quantity

    const itemPricingStructure =
      m2mPercentBasedItemsEnabled && isPercentOfReservationSale
        ? pricingStructure
        : DEFAULT_RECURRING_PRICING_STRUCTURE
    const itemPricePrecision = isPercentOfReservationSale
      ? "cents"
      : data.txn.product_sale_attributes.price_precision
    const requestData = {
      manage_id: marinaSlug,
      payment_method_id: data.payment_method_id,
      recurring_product_sale: {
        ledger_id: ledgerId,
        product_id: data.txn.product_sale_attributes.product_id,
        reservation_id: reservationId,
        due_day: data.monthly_due_date,
        start_date: format(data.start_date, "yyyy-MM-dd"),
        end_date:
          recurringEndSelection === "never"
            ? null
            : format(endDate, "yyyy-MM-dd"),
        name: data.txn.product_sale_attributes.name,
        original_amount: originalAmount,
        tax_rate: taxRate,
        quantity: itemQuantity,
        price_per_unit: pricePerUnit,
        price_precision: itemPricePrecision,
        pricing_structure: itemPricingStructure,
      },
    }
    if (onSubmit) {
      onSubmit(requestData)
      onModalClose()
      return
    }
    mutateRecurringProductSale(requestData)
  }

  const submitProductSale = (data) => {
    const {
      payment_schedule: paymentSchedule,
      custom_due_date: customDueDate,
      txn: {
        product_sale_attributes: {
          service_start_date: serviceStartDate,
          service_end_date: serviceEndDate,
          pricing_structure: pricingStructure,
        },
      },
      perDayDateType,
    } = data

    const serviceStart =
      serviceStartDate || parseDateValue(reservationCheckInDate)
    const serviceEnd =
      serviceEndDate || subDays(parseDateValue(reservationCheckOutDate), 1)

    const quantity = calculateQuantityIncludingMeteredElectric(data)
    const dueDate = getDueDate({ paymentSchedule, customDueDate })
    const parsedDueDate = isDate(dueDate)
      ? format(dueDate, "yyyy-MM-dd")
      : dueDate

    const isPerDay =
      data.txn.product_sale_attributes.pricing_structure ===
      RESERVATION_DATES_PRICING_STRUCTURE
    const isPercentOfReservationSale =
      data.txn.product_sale_attributes.pricing_structure ===
      PERCENT_OF_STORAGE_PRICING_STRUCTURE

    const { originalAmount, taxAmount, taxRate, amount, pricePerUnit } =
      getPriceAndTaxAmounts(
        data,
        isPerDay
          ? differenceInCalendarDays(serviceEnd, serviceStart) + 1
          : quantity,
        reservationSale
      )
    const meteredElectric = isMeteredElectricProduct
      ? {
          metered_electric_attributes: {
            meter_id: data.metered_electric.meter,
            reading: data.metered_electric.current_meter_reading,
            read_at: new Date(),
          },
        }
      : {}

    const pricePrecision = isPercentOfReservationSale
      ? "cents"
      : data.txn.product_sale_attributes.price_precision

    const requestData = {
      manage_id: marinaSlug,
      txn: {
        type: "Billing::ProductSaleTxn",
        amount,
        billing_ledger_id: ledgerId,
        product_sale_attributes: {
          original_amount: originalAmount,
          tax_amount: taxAmount,
          price_per_unit: pricePerUnit,
          price_precision: pricePrecision,
          reservation_id: reservationId,
          tax_rate: taxRate,
          pricing_structure:
            pricingStructure ?? DEFAULT_ONE_TIME_PRICING_STRUCTURE,
          product_id: data.txn.product_sale_attributes.product_id,
          service_start_date: format(serviceStart, "yyyy-MM-dd"),
          service_end_date: format(serviceEnd, "yyyy-MM-dd"),
          external_sale: data.txn.product_sale_attributes.external_sale,
          request_external_settlement:
            data.txn.product_sale_attributes.request_external_settlement,
          name: data.txn.product_sale_attributes.name,
          quantity: quantity ?? 1,
          reservation_dates: isPerDay && perDayDateType === "wholeReservation",
          reservation_sale: false,
          ...meteredElectric,
        },
      },
      invoices: [
        {
          amount,
          payment_method_id: data.payment_method_id,
          due_date: parsedDueDate,
          due_on_signature: paymentSchedule === "due_on_signature",
          add_to_installments: paymentSchedule === "add_to_installments",
        },
      ],
    }

    if (onSubmit) {
      onSubmit(requestData)
      onModalClose()
      return
    }

    if (amount === 0) {
      requestData.invoices = []
    }
    mutateProductSale(requestData)
  }

  const onProductIdChange = ({ target: { value } }) => {
    const {
      category,
      name,
      price_per_unit: pricePerUnit,
      price_precision: pricePrecision,
      tax_rate: taxRate,
    } = products.find(({ id }) => id === value)
    const unitPricing = priceType !== PERCENT_OF_STORAGE_PRICING_STRUCTURE
    const pricePerUnitInDollarsString =
      unitPricing && pricePerUnit
        ? pricePerUnitToDollars({
            pricePerUnit,
            precision: pricePrecision,
          }).toFixed(getPricePerUnitPrecision(pricePrecision))
        : null
    const taxPercent = taxRate ? toTaxPercent(taxRate) : 0

    setValue("txn.product_sale_attributes.name", name)
    setValue(
      "txn.product_sale_attributes.price_per_unit",
      pricePerUnitInDollarsString
    )
    setValue("txn.product_sale_attributes.price_precision", pricePrecision)
    setValue("txn.product_sale_attributes.tax_percent", taxPercent)

    if (itemWillRecur && NON_RECURRING_PRODUCT_CATEGORIES.includes(category)) {
      setValue("recurring_product", "no")

      if (priceType === PERCENT_OF_STORAGE_PRICING_STRUCTURE) {
        setValue(
          "txn.product_sale_attributes.pricing_structure",
          DEFAULT_ONE_TIME_PRICING_STRUCTURE
        )
      }
    }
  }

  const onRecurrenceChange = ({ target: { value } }) => {
    if (value === "yes") {
      setValue("payment_schedule", "")
      setExpandAdvancedOptions(false)
    } else {
      resetField("payment_schedule")
    }
  }

  const setRecurringPercentItemDatesToStorageDates = () => {
    if (isMonthToMonth || isMonthlyInstallments) {
      let storageStart
      let storageEnd
      if (isSignedContractView) {
        const signedStartDate =
          reservationSale.product_sale.recurring_product_sale.start_date
        const signedEndDate =
          reservationSale.product_sale.recurring_product_sale.end_date

        storageStart = parseDateValue(signedStartDate)
        storageEnd = signedEndDate ? parseDateValue(signedEndDate) : null
      } else {
        storageStart = contractStartDate || new Date()
        storageEnd = isMonthToMonth ? null : contractEndDate
      }

      setValue("start_date", storageStart)
      setValue("recurring_end_selection", storageEnd ? "onDate" : "never")
      setValue("end_date", storageEnd)
    }
  }

  const onPercentBasedPricingToggle = ({ target: { checked } }) => {
    if (checked) {
      setValue(
        "txn.product_sale_attributes.pricing_structure",
        PERCENT_OF_STORAGE_PRICING_STRUCTURE
      )
      onPricingStructureChange(PERCENT_OF_STORAGE_PRICING_STRUCTURE)
      setRecurringPercentItemDatesToStorageDates()
    } else {
      setValue(
        "txn.product_sale_attributes.pricing_structure",
        DEFAULT_ONE_TIME_PRICING_STRUCTURE
      )
      onPricingStructureChange(DEFAULT_ONE_TIME_PRICING_STRUCTURE)
    }
  }

  useEffect(() => {
    if (
      perDayDateType === "wholeReservation" &&
      !["reservation_check_in", "reservation_check_out"].includes(
        paymentSchedule
      )
    ) {
      setValue("payment_schedule", "reservation_check_in")
    }
  }, [perDayDateType, setValue, paymentSchedule])

  useEffect(() => {
    // helps reinitialize fields that were previously hidden
    if (perDayDateType === "custom") {
      resetField("txn.product_sale_attributes.service_start_date")
      resetField("txn.product_sale_attributes.service_end_date")
    }
  }, [perDayDateType, resetField])

  const onPricingStructureChange = (pricingStructure) => {
    clearErrors("txn.product_sale_attributes.quantity")
    if (pricingStructure === DEFAULT_ONE_TIME_PRICING_STRUCTURE) {
      resetField("payment_schedule")
      resetField("txn.product_sale_attributes.service_start_date")
      resetField("txn.product_sale_attributes.service_end_date")
    } else if (pricingStructure === PERCENT_OF_STORAGE_PRICING_STRUCTURE) {
      setValue("txn.product_sale_attributes.quantity", null)
      setValue("txn.product_sale_attributes.price_per_unit", null)
      setValue(
        "recurring_product",
        isMonthToMonth || isMonthlyInstallments ? "yes" : "no"
      )
    } else {
      setValue("payment_schedule", "reservation_check_in")
      setValue("perDayDateType", "wholeReservation")
    }
  }

  const onModalClose = () => {
    onClose()
    setExpandAdvancedOptions(false)
    setPaymentScheduleDisabled(false)
  }

  const isRecurring = itemWillRecur === "yes"
  const isNonRecurringProduct = NON_RECURRING_PRODUCT_CATEGORIES.includes(
    products.find((product) => product.id === productId)?.category
  )
  const isMeteredElectricProduct =
    products.find((product) => product.id === productId)?.category ===
    "metered_electric"
  const isNotReservationOrIsNotPerDay =
    priceType !== RESERVATION_DATES_PRICING_STRUCTURE || !reservationId

  useEffect(() => {
    if (isMeteredElectricProduct) {
      resetField("payment_schedule")
    }
  }, [isMeteredElectricProduct, resetField, setValue])

  const productByCategory = useMemo(() => {
    return products.reduce((accum, product) => {
      const category = product.product_category?.display_name || "Uncategorized"
      if (!accum[category]) {
        return {
          ...accum,
          [category]: [product],
        }
      }
      accum[category].push(product)
      return accum
    }, {})
  }, [products])

  useEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  // when the advanced options are open, scroll to the bottom of the modal
  useEffect(() => {
    if (expandAdvancedOptions) {
      bodyRef.current.scrollTo(0, bodyRef.current.scrollHeight)
    }
  }, [expandAdvancedOptions])

  const renderProductSelect = () => {
    return (
      <div className="flex flex-col">
        <Form.Label htmlFor="product-id">Product type</Form.Label>
        <Form.Select
          id="product-id"
          {...register("txn.product_sale_attributes.product_id", {
            required: "Item is required.",
            onChange: onProductIdChange,
          })}
          hasErrors={Boolean(errors.txn?.product_sale_attributes?.product_id)}
        >
          <option disabled value="">
            Select
          </option>
          {Object.keys(productByCategory).map((category) => {
            return (
              <optgroup label={snakecaseToTitlecase(category)} key={category}>
                {productByCategory[category].map(
                  ({ id, name, is_inventory: isInventory }) => (
                    <option key={id} value={id}>
                      {`${name}${isInventory ? " (inventory item)" : ""}`}
                    </option>
                  )
                )}
              </optgroup>
            )
          })}
        </Form.Select>
        <input
          {...register("txn.product_sale_attributes.price_precision")}
          type="hidden"
        />
        {errors.txn?.product_sale_attributes?.product_id && (
          <Form.Error>
            {errors.txn.product_sale_attributes.product_id.message}
          </Form.Error>
        )}
      </div>
    )
  }

  const renderRecurrence = () => {
    return (
      <div className="flex w-1/2 flex-col">
        <Form.Label htmlFor="recurring-product">Recurrence</Form.Label>
        <Form.Select
          id="recurring-product"
          disabled={priceType === PERCENT_OF_STORAGE_PRICING_STRUCTURE}
          {...register("recurring_product", {
            onChange: onRecurrenceChange,
          })}
        >
          <option key="no" value="no">
            Does not repeat
          </option>
          <option key="yes" value="yes" disabled={isNonRecurringProduct}>
            Repeats monthly
          </option>
        </Form.Select>
      </div>
    )
  }

  const renderContractPercentBasedOption = () => {
    const recurringItemOnInstallmentsContract =
      isRecurring && !isMonthToMonth && !isMonthlyInstallments
    const nonRecurringItemOnMonthToMonthContract =
      !isRecurring && isMonthToMonth
    const nonRecurringItemOnMonthlyInstallmentsContract =
      !isRecurring && isMonthlyInstallments
    const noSaleToTakePercentOf = isSignedContractView && !reservationSale
    const disableInput =
      recurringItemOnInstallmentsContract ||
      nonRecurringItemOnMonthToMonthContract ||
      nonRecurringItemOnMonthlyInstallmentsContract ||
      noSaleToTakePercentOf
    return (
      <>
        <div className="flex flex-row">
          <Form.Checkbox
            label="This is a percent-based item"
            name="pricing-structure"
            id="pricing-structure"
            checked={priceType === PERCENT_OF_STORAGE_PRICING_STRUCTURE}
            onChange={onPercentBasedPricingToggle}
            hasErrors={Boolean(
              errors?.txn?.product_sale_attributes?.pricing_structure
            )}
            disabled={disableInput}
          />
          {errors?.txn?.product_sale_attributes?.pricing_structure && (
            <Form.Error>
              {errors.txn.product_sale_attributes.pricing_structure.message}
            </Form.Error>
          )}
        </div>
      </>
    )
  }

  const renderMonthlyDueDate = () => {
    return (
      <div className="flex w-1/2 flex-col">
        <DueDayDropdown
          name="monthly_due_date"
          register={register}
          labelText="Monthly due date"
          errors={errors.monthly_due_date}
        />
      </div>
    )
  }

  const renderRecurringStart = () => {
    const disabled =
      (isMonthToMonth || isMonthlyInstallments) &&
      priceType === PERCENT_OF_STORAGE_PRICING_STRUCTURE

    return (
      <div className="flex w-1/2 flex-col">
        <Form.Label htmlFor="starts-selection">Starts</Form.Label>
        <div className="flex flex-col gap-4">
          <Form.Select id="starts-selection" value="onDate" disabled>
            <option key="onDate" value="onDate">
              On Custom Date
            </option>
          </Form.Select>
          <RecurringStartDateField
            endDate={endDate}
            control={control}
            errors={errors}
            disabled={disabled}
          />
        </div>
      </div>
    )
  }

  const renderRecurringEnd = () => {
    const disableEndsSelection =
      !isMonthToMonth ||
      ((isMonthToMonth || isMonthlyInstallments) &&
        priceType === PERCENT_OF_STORAGE_PRICING_STRUCTURE)
    const disableEndDateInput =
      (isMonthToMonth || isMonthlyInstallments) &&
      priceType === PERCENT_OF_STORAGE_PRICING_STRUCTURE

    return (
      <div className="flex w-1/2 flex-col">
        <Form.Label htmlFor="ends-selection">Ends</Form.Label>
        <div className="flex flex-col gap-4">
          <Form.Select
            id="ends-selection"
            disabled={disableEndsSelection}
            name="recurring_end_selection"
            {...register("recurring_end_selection")}
          >
            {isMonthToMonth ? (
              <option key="never" value="never">
                Never
              </option>
            ) : null}
            <option key="onDate" value="onDate">
              On Custom Date
            </option>
          </Form.Select>
          {recurringEndSelection === "onDate" ? (
            <RecurringEndDateField
              control={control}
              errors={errors}
              startDate={startDate}
              disabled={disableEndDateInput}
            />
          ) : null}
        </div>
      </div>
    )
  }

  const renderRecurringDates = () => {
    return (
      <>
        <div className="flex flex-row gap-4">
          {renderRecurringStart()}
          {renderRecurringEnd()}
        </div>
        {(isMonthToMonth || isMonthlyInstallments) &&
          priceType === PERCENT_OF_STORAGE_PRICING_STRUCTURE && (
            <div className="flex flex-row items-center space-x-2">
              <i className="icon icon-info-circle text-lg text-yellow-700" />
              <span>
                Percent-based items will match contract itinerary dates.
              </span>
            </div>
          )}
      </>
    )
  }

  const renderServiceDates = () => {
    return (
      <div className="flex flex-row gap-4">
        <DateSetFields
          control={control}
          errors={errors}
          endDate={serviceEndDate}
          startDate={serviceStartDate}
        />
      </div>
    )
  }

  const renderReservationPerDayCustomDateInputs = () => {
    return (
      <div className="flex flex-row gap-4">
        <DateSetFields
          control={control}
          errors={errors}
          endDate={serviceEndDate}
          startDate={serviceStartDate}
          showSelector={false}
          startLabel="Start date"
          endLabel="End date"
        />
      </div>
    )
  }

  const renderPaymentSchedule = () => {
    return (
      <PaymentScheduleDropdown
        register={register}
        errors={errors}
        disabled={paymentScheduleDisabled}
        hasScheduledInvoice={hasScheduledInvoice}
        hasReservation={!!reservationId}
        priceType={priceType}
        dateType={perDayDateType}
        isMonthToMonth={isMonthToMonth}
        isMonthlyInstallments={isMonthlyInstallments}
      />
    )
  }

  const renderPaymentMethod = () => {
    return (
      <PaymentMethodDropdown
        name="payment_method_id"
        disabled={!paymentMethods}
        onlineMethods={paymentCards}
        errors={errors.payment_method_id}
        registerOptions={
          paymentMethods ? { required: "Payment method is required." } : {}
        }
        control={control}
        includeExpirationDates={false}
      />
    )
  }

  const renderCustomDueDateFields = () => {
    if (paymentSchedule !== "custom_due_date") {
      return null
    }

    return (
      <div className="flex flex-row gap-4">
        <div className="flex w-1/2 flex-col">
          {reservationId ? renderPaymentMethod() : null}
        </div>
        <div className="flex w-1/2 flex-col">
          <Form.Label htmlFor="custom-due-date">Due date</Form.Label>
          <Controller
            name="custom_due_date"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Form.DatePicker
                id="custom-due-date"
                renderCustomHeader={(props) => (
                  <Form.DatePicker.QuickNavHeader {...props} />
                )}
                {...{ onChange, value }}
                hasErrors={Boolean(errors.custom_due_date)}
              />
            )}
            rules={{
              required: "Custom due date is required",
            }}
          />
          {errors.custom_due_date && (
            <Form.Error>{errors.custom_due_date.message}</Form.Error>
          )}
        </div>
      </div>
    )
  }

  const renderPriceTypeSelect = () => {
    if (isContractView && m2mPercentBasedItemsEnabled) {
      return null
    }

    return (
      <div className="flex w-full flex-col pb-4">
        <Form.Label htmlFor="pricing-structure">Price type</Form.Label>
        <Form.Select
          id="pricing-structure"
          {...register("txn.product_sale_attributes.pricing_structure", {
            onChange: (event) => onPricingStructureChange(event.target.value),
          })}
          hasErrors={Boolean(
            errors?.txn?.product_sale_attributes?.pricing_structure
          )}
        >
          <option key="per-unit" value={DEFAULT_ONE_TIME_PRICING_STRUCTURE}>
            Per unit
          </option>
          {isReservationView && (
            <option key="per-day" value={RESERVATION_DATES_PRICING_STRUCTURE}>
              Per day
            </option>
          )}
          {canShowPercentOfStorageType && (
            <option
              key="percent-of-storage"
              value={PERCENT_OF_STORAGE_PRICING_STRUCTURE}
              disabled={
                isMonthToMonth ||
                isMonthlyInstallments ||
                (reservationId && !reservationSale)
              }
            >
              Percent of storage
            </option>
          )}
        </Form.Select>
        {errors?.txn?.product_sale_attributes?.pricing_structure && (
          <Form.Error>
            {errors.txn.product_sale_attributes.pricing_structure.message}
          </Form.Error>
        )}
      </div>
    )
  }

  const renderAdvancedOptionHeader = () => {
    return (
      <h5
        className="my-0 text-lg font-semibold"
        onClick={() => setExpandAdvancedOptions(!expandAdvancedOptions)}
      >
        Advanced options
        <i
          className={classNames("icon ml-2 text-xs", {
            "icon-angle-up": expandAdvancedOptions,
            "icon-angle-down": !expandAdvancedOptions,
          })}
        />
      </h5>
    )
  }

  const renderAdvancedOptions = () => {
    return (
      <div
        className={classNames(
          "max-h-0 overflow-hidden transition-[max-height] duration-300 ease-in-out",
          {
            "max-h-80": expandAdvancedOptions,
          }
        )}
      >
        {!isMeteredElectricProduct ? (
          <div className="flex w-full flex-col pb-4">
            {renderPriceTypeSelect()}
          </div>
        ) : null}
        <div className="flex flex-row">
          <Form.Checkbox
            {...register("txn.product_sale_attributes.external_sale")}
            label="This is an external sale"
            id="external-sale"
          />
          {isUnsignedContractView && (
            <Form.Checkbox
              {...register(
                "txn.product_sale_attributes.request_external_settlement"
              )}
              label="Settle via external transaction"
              id="request-external-settlement"
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onModalClose}
      afterLeave={reset}
      size="mediumFixed"
    >
      <Header
        title={`${initialValues ? "Edit" : "Add"} item`}
        manageItemsUrl={`/manage/${marinaSlug}/sales/edit`}
      />
      <Modal.Body ref={bodyRef}>
        <Form>
          <ReloadableWidget
            isLoading={
              isLoadingProducts || isFetchingProducts || hasNextPage !== false
            }
            isError={isErrorProducts}
            showOverlay
          >
            <div className="flex flex-col gap-4">
              {renderProductSelect()}
              {isContractView &&
                !isMeteredElectricProduct &&
                m2mPercentBasedItemsEnabled &&
                renderContractPercentBasedOption()}
              {isMeteredElectricProduct ? (
                <>
                  <MeterField
                    marinaSlug={marinaSlug}
                    reservationId={reservationId}
                    register={register}
                    setValue={setValue}
                  />
                  <ItemPricingFields
                    errors={errors}
                    precision={precision}
                    register={register}
                    showQuantity={false}
                    priceType={priceType}
                  />
                  <MeterReadingFields
                    errors={errors}
                    lastMeterReading={lastMeterReading}
                    register={register}
                  />
                </>
              ) : (
                <ItemPricingFields
                  errors={errors}
                  precision={precision}
                  register={register}
                  showQuantity={PRICING_STRUCTURES_WITH_QUANTITY.includes(
                    priceType
                  )}
                  priceType={priceType}
                />
              )}
            </div>
            <Divider />
            <div className="flex flex-col gap-4">
              {perDayDateType === "custom"
                ? renderReservationPerDayCustomDateInputs()
                : null}
              {isRecurring ? (
                <>
                  <div className="flex flex-row gap-4">
                    {isNotReservationOrIsNotPerDay && renderRecurrence()}
                    {renderMonthlyDueDate()}
                  </div>
                  {renderRecurringDates()}
                </>
              ) : (
                <>
                  <div className="flex flex-row gap-4">
                    {isNotReservationOrIsNotPerDay && renderRecurrence()}
                    {renderPaymentSchedule()}
                  </div>
                  {renderCustomDueDateFields()}
                  {isNotReservationOrIsNotPerDay && renderServiceDates()}
                </>
              )}
            </div>
            {!isRecurring && <Divider />}
            <div className="flex flex-col gap-4">
              {!isRecurring && renderAdvancedOptionHeader()}
              {renderAdvancedOptions()}
            </div>
          </ReloadableWidget>
        </Form>
      </Modal.Body>
      <Footer
        submitButtonText={initialValues ? "Edit" : "Add"}
        onSubmit={handleSubmit(submitForm)}
        onCancel={onModalClose}
        isLoading={
          isLoadingMutateProductSale || isLoadingMutateRecurringProductSale
        }
        disabled={
          isLoadingMutateProductSale ||
          isLoadingMutateRecurringProductSale ||
          hasNextPage ||
          isFetchingProducts
        }
      />
    </Modal>
  )
}

const addItemsFormShape = PropTypes.shape({
  payment_method_id: PropTypes.number,
  payment_schedule: PropTypes.string,
  recurring_product: PropTypes.string,
  start_date: PropTypes.object,
  end_date: PropTypes.object,
  recurring_end_selection: PropTypes.string,
  monthly_due_date: PropTypes.number,
  custom_due_date: PropTypes.object,
  txn: PropTypes.shape({
    product_sale_attributes: PropTypes.shape({
      name: PropTypes.string,
      product_id: PropTypes.string,
      price_per_unit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      quantity: PropTypes.number,
      tax_percent: PropTypes.number,
      service_start_date: PropTypes.object,
      service_end_date: PropTypes.object,
      external_sale: PropTypes.bool,
      requestExternalSettlement: PropTypes.bool,
    }),
  }),
})

AddItemModal.propTypes = {
  ledgerId: PropTypes.string,
  contractEndDate: PropTypes.instanceOf(Date),
  contractStartDate: PropTypes.instanceOf(Date),
  contractQuoteId: PropTypes.number,
  reservationId: PropTypes.number,
  paymentMethods: PropTypes.shape({
    default: PropTypes.shape({
      id: PropTypes.number,
    }),
    cards: PropTypes.array,
  }),
  onItemAdded: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  initialValues: addItemsFormShape,
  isMonthToMonth: PropTypes.bool.isRequired,
  isMonthlyInstallments: PropTypes.bool.isRequired,
  hasScheduledInvoice: PropTypes.bool,
  reservationCheckInDate: PropTypes.string,
  reservationCheckOutDate: PropTypes.string,
  reservationSale: PropTypes.shape({
    product_sale: PropTypes.shape({
      original_amount: PropTypes.number,
      discount_amount: PropTypes.number,
      recurring_product_sale: PropTypes.shape({
        start_date: PropTypes.string,
        end_date: PropTypes.string,
      }),
    }),
  }),
  m2mPercentBasedItemsEnabled: PropTypes.bool,
}

export default AddItemModal
