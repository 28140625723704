import PropTypes from "prop-types"
import React from "react"
import { useForm } from "react-hook-form"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"

const MinimumPrice = ({ modifier, onClose, mergeModifierData }) => {
  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      modifier,
    },
  })

  const onSaveButtonClick = (data) => {
    mergeModifierData(data)
    onClose()
  }

  const onDeleteButtonClick = () => {
    setValue("modifier.fixedAmount", null)
    mergeModifierData(getValues())
    onClose()
  }

  return (
    <>
      <Modal.Body>
        <div className="w-2/3">
          <div className="flex flex-row gap-x-4">
            <div className="w-1/2">
              <Form.Label htmlFor="minimum-price">Minimum Price</Form.Label>
              <Form.IconTextField
                icon={<span>$</span>}
                position="left"
                id="minimum-price"
                {...register("modifier.fixedAmount")}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="mt-5 flex justify-between">
          <Button variant="tertiary" onClick={onDeleteButtonClick}>
            Delete
          </Button>
          <div className="flex justify-end space-x-2">
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmit(onSaveButtonClick)}>
              Save Changes
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  )
}

MinimumPrice.propTypes = {
  modifier: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  mergeModifierData: PropTypes.func.isRequired,
}

export default MinimumPrice
