import PropTypes from "prop-types"
import React from "react"
import DockwaDealCard from "src/main/MarinaProfile/DockwaDeals/DockwaDealCard"
import { DockwaDealsLogo } from "src/main/MarinaProfile/logos"

import Modal from "src/components/Modal"

const ViewAllDealsModal = ({ isOpen, setIsOpen, dockwaDeals }) => {
  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Modal.Header>
        <DockwaDealsLogo />
        <div className="pt-1">
          The following discounts automatically apply for Dockwa+ members on
          eligible dates.
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col space-y-4">
          {dockwaDeals.map((dockwaDeal) => (
            <DockwaDealCard dockwaDeal={dockwaDeal} key={dockwaDeal.id} />
          ))}
        </div>
      </Modal.Body>
    </Modal>
  )
}

ViewAllDealsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  dockwaDeals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      discount: PropTypes.number.isRequired,
      category: PropTypes.string.isRequired,
      expiresAt: PropTypes.string,
      validRangeReadable: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      readableDiscount: PropTypes.string.isRequired,
      expiresSoon: PropTypes.bool.isRequired,
    })
  ),
}

export default ViewAllDealsModal
