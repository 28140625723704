import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation } from "react-query"

import updateCruiseStopOrder from "src/api/CruisePlan/updateCruiseStopOrder"

import { useToast } from "src/hooks/use_toast"

import CruisePlanEmptyState from "./CruisePlanEmptyState"
import CruiseStopItem from "./CruiseStopItem"
import displayDuration from "./displayDuration"

const CruisePlan = ({ cruisePlan }) => {
  const [stops, setStops] = useState(cruisePlan.cruiseStops)
  const { showToast } = useToast()

  const { mutate } = useMutation({
    mutationFn: (data) => updateCruiseStopOrder(cruisePlan.id, data),
    onError: () => {
      showToast("Error updating sort order", { type: "error" })
    },
  })

  const totalDuration = stops.reduce((acc, stop) => {
    return acc + parseFloat(stop.duration) || 0
  }, 0)

  const totalNauticalMiles = stops.reduce((acc, stop) => {
    return acc + parseFloat(stop.nauticalMiles) || 0
  }, 0)

  const totalStops = stops.length

  const handleMoveStop = (id, direction) => {
    setStops((stops) => {
      // find the stop to move and its index
      const index = stops.findIndex((stop) => stop.id === id)
      if (index === -1) return stops
      const stop = stops[index]

      // if the stop is at the top and we're moving up, or at the bottom and moving down, do nothing
      if (direction === "up" && stop.display_order === 0) return stops
      if (direction === "down" && stop.display_order === stops.length - 1)
        return stops

      // find the stop to swap with
      const swapIndex = index + (direction === "up" ? -1 : 1)
      if (swapIndex < 0 || swapIndex >= stops.length) return stops
      const swapStop = stops[swapIndex]
      if (!swapStop) return stops

      // swap the stops
      const newStops = [...stops]
      const [movedStop] = newStops.splice(index, 1)
      newStops.splice(swapIndex, 0, movedStop)

      // update the stop order
      const stopOrder = newStops.map((stop, index) => ({
        id: stop.id,
        display_order: index,
      }))
      mutate(stopOrder)
      return newStops
    })
  }

  const renderAddToPlanButton = () => {
    return (
      <a
        href={`/account/cruise_plans/${cruisePlan.id}/cruise_stops`}
        className="btn btn-secondary"
      >
        Add to Plan
      </a>
    )
  }

  const renderStop = () => {
    let previousStop = null
    const stopItems = []
    stops.forEach((stop) => {
      stopItems.push(
        <CruiseStopItem
          key={stop.id}
          stop={stop}
          previousStop={previousStop}
          cruisePlanId={cruisePlan.id}
          handleMoveStop={handleMoveStop}
        />
      )
      previousStop = stop
    })
    return stopItems
  }

  const renderStops = () => {
    return (
      <>
        <div className="mb-4 flex flex-col lg:flex-row lg:items-center lg:justify-between">
          {renderAddToPlanButton()}
          <div className="flex gap-2">
            {totalStops > 0 && (
              <div className="flex flex-col items-center rounded-lg border p-2 text-gray-900">
                <span className="font-bold">{totalStops}</span>
                <span className="text-gray-500">Stops</span>
              </div>
            )}
            {totalNauticalMiles > 0 && (
              <div className="flex flex-col items-center rounded-lg border p-2 text-gray-900">
                <span className="font-bold">{totalNauticalMiles} nm</span>
                <span className="text-gray-500">Trip Distance</span>
              </div>
            )}
            {totalDuration > 0 && (
              <div className="flex flex-col items-center rounded-lg border p-2 text-gray-900">
                <span className="font-bold">
                  {displayDuration(totalDuration)}
                </span>
                <span className="text-gray-500">Trip Duration</span>
              </div>
            )}
          </div>
        </div>
        <div className="table-responsive overflow-y-visible">
          <table className="table-bordered table-hover mb-40 table">
            <thead>
              <tr>
                <th className="shrink bg-gray-100"></th>
                <th className="bg-gray-100 px-4">Stop</th>
                <th className="bg-gray-100 px-4">Latitude</th>
                <th className="bg-gray-100 px-4">Longitude</th>
                <th className="bg-gray-100 px-4">Date of arrival</th>
                <th className="bg-gray-100 px-4">Date of departure</th>
                <th className="bg-gray-100 px-4">Time of arrival</th>
                <th className="bg-gray-100 px-4">Time of departure</th>
                <th className="bg-gray-100 px-4">Nautical miles</th>
                <th className="bg-gray-100 px-4">Knots</th>
                <th className="bg-gray-100 px-4">Duration</th>
                <th className="bg-gray-100 px-4">Notes</th>
                <th className="bg-gray-100 px-4">Status</th>
                <th className="bg-gray-100 px-4">Actions</th>
              </tr>
            </thead>
            <tbody>{renderStop()}</tbody>
          </table>
        </div>
      </>
    )
  }

  return (
    <div className="container-fluid mb-8">
      <div className="flex items-center justify-between">
        <a href="/account/cruise_plans" className="text-link">
          ← Cruise plans
        </a>
        <a
          href={`/account/cruise_plans/${cruisePlan.id}/edit`}
          className="btn btn-secondary"
        >
          Edit plan details
        </a>
      </div>

      <div className="flex gap-4">
        <img
          className="cover size-24 rounded object-cover"
          src={cruisePlan.coverImage}
        />
        <div className="flex flex-col">
          <div className="text-2xl font-bold">{cruisePlan.name}</div>
          <div className="text-gray-500">{cruisePlan.description}</div>
        </div>
      </div>

      <hr className="my-4" />
      {stops.length > 0 ? (
        renderStops()
      ) : (
        <CruisePlanEmptyState
          title="No stops yet"
          description="Add your first stop."
          button={renderAddToPlanButton()}
        />
      )}
    </div>
  )
}

CruisePlan.propTypes = {
  cruisePlan: PropTypes.shape({
    id: PropTypes.string.isRequired,
    cruiseStops: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      })
    ).isRequired,
    coverImage: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}

export default CruisePlan
