import PropTypes from "prop-types"
import React, { createContext, useEffect, useMemo } from "react"
import { useInfiniteQuery, useQueryClient } from "react-query"
import { collapseReservationSalesAndDates } from "src/main/Billing/Items/helpers"

import { queryProductSaleTxns } from "src/api/Billing/Items"
import { queryInvoices } from "src/api/Billing/Payments"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

export const SharedBillingContext = createContext(null)

const SharedBillingContextProvider = ({
  children,
  reservationId,
  contractQuoteId,
  editable,
}) => {
  const queryClient = useQueryClient()
  const marinaSlug = getCurrentMarinaSlug()

  const {
    isFetching: isLoadingItems,
    isError: isErrorItems,
    data: itemsData,
    fetchNextPage: fetchNextTxnPage,
    hasNextPage: hasNextTxnPage,
    isFetchingNextPage: isFetchingNextTxnPage,
  } = useInfiniteQuery({
    queryKey: ["productSaleTxns", marinaSlug, contractQuoteId],
    queryFn: ({ pageParam = 1 }) =>
      queryProductSaleTxns({
        marinaSlug,
        reservationId,
        page: pageParam,
        includeInvoices: true,
      }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length) {
        return pages.length + 1
      }
    },
    select: (data) => {
      return data?.pages ? data.pages.flatMap((page) => page) : undefined
    },
    refetchOnWindowFocus: false,
  })

  const {
    isFetching: isLoadingInvoices,
    isError: isErrorInvoices,
    data: invoicesData,
    fetchNextPage: fetchNextInvoicePage,
    hasNextPage: hasNextInvoicePage,
    isFetchingNextPage: isFetchingNextInvoicePage,
  } = useInfiniteQuery({
    queryKey: ["invoices", contractQuoteId],
    queryFn: ({ pageParam = 1 }) =>
      queryInvoices({ marinaSlug, reservationId, page: pageParam }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length) {
        return pages.length + 1
      }
    },
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (!isFetchingNextTxnPage && hasNextTxnPage) {
      fetchNextTxnPage()
    }
  }, [isFetchingNextTxnPage, hasNextTxnPage, fetchNextTxnPage])

  useEffect(() => {
    if (!isFetchingNextInvoicePage && hasNextInvoicePage) {
      fetchNextInvoicePage()
    }
  }, [isFetchingNextInvoicePage, hasNextInvoicePage, fetchNextInvoicePage])

  const invoices = invoicesData?.pages
    ? invoicesData?.pages.flatMap((page) => page)
    : undefined

  const refreshItems = () => {
    queryClient.refetchQueries({
      queryKey: ["productSaleTxns", marinaSlug, contractQuoteId],
    })
  }

  const refreshPayments = () => {
    queryClient.refetchQueries({
      queryKey: ["invoices", contractQuoteId],
    })
  }

  const flattenedItems = useMemo(() => {
    return itemsData ? collapseReservationSalesAndDates(itemsData) : undefined
  }, [itemsData])

  return (
    <SharedBillingContext.Provider
      value={{
        reservationId,
        contractQuoteId,
        items: {
          data: itemsData,
          collapsedData: flattenedItems,
          isLoading: isLoadingItems || hasNextTxnPage !== false,
          isError: isErrorItems,
        },
        invoices: {
          data: invoices,
          isLoading: isLoadingInvoices || hasNextInvoicePage !== false,
          isError: isErrorInvoices,
        },
        refreshItems,
        refreshPayments,
        editable,
      }}
    >
      {children}
    </SharedBillingContext.Provider>
  )
}

SharedBillingContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  reservationId: PropTypes.number.isRequired,
  contractQuoteId: PropTypes.number,
  editable: PropTypes.bool.isRequired,
}

export default SharedBillingContextProvider
