import { format, parseISO } from "date-fns"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query"
import PrintReceiptModal from "src/main/PointOfSale/Sales/PrintReceiptModal"

import OverflowMenu from "src/components/OverflowMenu"
import ReloadableWidget from "src/components/ReloadableWidget"
import Tooltip from "src/components/Tooltip"

import { fetchCheckout } from "src/api/PointOfSale/checkout"
import { queryPOSProductSaleTxnsByCheckout } from "src/api/PointOfSale/sales"

import { formattedCentsToDollars } from "src/utils/UnitConversion"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { SaleDetailsContext } from "../SaleDetailsContext"
import { checkoutProps } from "../props"
import SaleCheckoutHeading from "./SaleCheckoutHeading"
import SaleCheckoutItems from "./SaleCheckoutItems"

const SaleCheckout = ({ checkout, index }) => {
  const queryClient = useQueryClient()
  const marinaSlug = getCurrentMarinaSlug()
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(index === 0)
  const [printModalOpen, setPrintModalOpen] = useState(false)
  const {
    payments: { data: payments },
    receiptPrinters,
    marinaAccess,
  } = useContext(SaleDetailsContext)
  const checkoutId = checkout.id

  const { data: checkoutData } = useQuery(
    ["posCheckout", marinaSlug, checkoutId],
    () => fetchCheckout({ marinaSlug, checkoutId }),
    {
      initialData: checkout,
      staleTime: Infinity,
    }
  )

  const previewPath = `/manage/${marinaSlug}/point_of_sale/checkouts/${checkoutId}`
  const handlePreviewClick = () => {
    window.open(previewPath, "_blank")
  }

  const {
    isFetching: isLoadingCheckoutTxns,
    isError: isErrorCheckoutTxns,
    data: checkoutTxnsData,
    fetchNextPage: fetchNextCheckoutTxnPage,
    hasNextPage: hasNextCheckoutTxnPage,
    isFetchingNextPage: isFetchingNextCheckoutTxnPage,
  } = useInfiniteQuery({
    queryKey: ["posCheckoutTxns", marinaSlug, checkoutId],
    queryFn: ({ pageParam = 1 }) =>
      queryPOSProductSaleTxnsByCheckout({
        marinaSlug,
        checkoutId,
        page: pageParam,
      }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length) {
        return pages.length + 1
      }
    },
    select: (data) =>
      data?.pages ? data.pages.flatMap((page) => page) : undefined,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (!isFetchingNextCheckoutTxnPage && hasNextCheckoutTxnPage) {
      fetchNextCheckoutTxnPage()
    }
  }, [
    isFetchingNextCheckoutTxnPage,
    hasNextCheckoutTxnPage,
    fetchNextCheckoutTxnPage,
  ])

  const refreshCheckoutTxns = () => {
    queryClient.refetchQueries({
      queryKey: ["posCheckoutTxns", marinaSlug, checkoutId],
    })
  }

  // removing an item updates payments and will trigger refresh
  // settling an invoice updates payments and will trigger refresh
  useEffect(() => {
    refreshCheckoutTxns()
  }, [payments])

  const checkoutDate = parseISO(checkoutData.createdAt)
  const nonReturnedSales = checkoutTxnsData?.filter(
    (txn) => txn.child_txns.length === 0
  )
  const nonReturnedSalesCount = nonReturnedSales?.length
  const txnCountText =
    checkoutTxnsData &&
    `(${nonReturnedSalesCount} ${
      nonReturnedSalesCount === 1 ? "item" : "items"
    })`

  const checkoutTotal = () =>
    (checkoutTxnsData || []).reduce((acc, item) => acc + item.net_amount, 0)

  const renderPrintMenuItem = () => {
    const isDisabled = receiptPrinters.length === 0
    const isNotPermitted = !marinaAccess.manageHardware
    const menuItem = (
      <OverflowMenu.Item
        label="Print Receipt"
        onClick={() => setPrintModalOpen(true)}
        disabled={isNotPermitted || isDisabled}
      />
    )

    if (isNotPermitted) {
      const text =
        "The ability to print receipts is available within the POS module.\nContact your Dockwa representative or our support team to learn more."
      return (
        <Tooltip placement="top" variant="dark" text={text}>
          {menuItem}
        </Tooltip>
      )
    }

    if (isDisabled) {
      return (
        <Tooltip
          placement="top"
          variant="dark"
          text="Configure a receipt printer in Dockwa settings (Account → Settings → Hardware)"
        >
          {menuItem}
        </Tooltip>
      )
    }

    return menuItem
  }

  return (
    <div className="my-6">
      <ReloadableWidget
        isLoading={!checkoutTxnsData && isLoadingCheckoutTxns}
        isError={isErrorCheckoutTxns}
      >
        <div className="flex justify-between bg-blue-400 px-3 py-2 text-base font-semibold text-white">
          <div className="flex gap-2">
            <i
              className={`icon ${
                isCheckoutOpen ? "icon-chevron-down" : "icon-chevron"
              } text-lg font-semibold`}
              onClick={() => setIsCheckoutOpen(!isCheckoutOpen)}
            />
            <span>{format(checkoutDate, "MMMM d, yyyy")}</span>
          </div>
          <div className="flex gap-8">
            <span className="text-nowrap">{txnCountText}</span>
            <span>{formattedCentsToDollars(checkoutTotal())}</span>
            {nonReturnedSalesCount > 0 ? (
              <OverflowMenu variant="transparent">
                <span className="text-sm font-normal">
                  <OverflowMenu.Item
                    label="View Invoice"
                    onClick={handlePreviewClick}
                  />
                </span>
                <span className="text-sm font-normal">
                  {renderPrintMenuItem()}
                </span>
              </OverflowMenu>
            ) : (
              <span className="w-7" />
            )}
          </div>
        </div>
        {isCheckoutOpen && (
          <div key={checkoutId} className="overflow-x-auto md:overflow-visible">
            <SaleCheckoutHeading checkout={checkoutData} />
            <SaleCheckoutItems
              items={checkoutTxnsData}
              isLoading={isLoadingCheckoutTxns}
              isError={isErrorCheckoutTxns}
            />
          </div>
        )}
      </ReloadableWidget>
      {printModalOpen ? (
        <PrintReceiptModal
          onClose={() => setPrintModalOpen(false)}
          checkoutId={checkoutId}
        />
      ) : null}
    </div>
  )
}

SaleCheckout.propTypes = {
  checkout: PropTypes.shape(checkoutProps).isRequired,
  index: PropTypes.number.isRequired,
}

export default SaleCheckout
