import classNames from "classnames"
import { format, parseISO } from "date-fns"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { GUEST_DISPLAY_NAME } from "src/main/PointOfSale/constants"

import Button from "src/components/Button"
import Table from "src/components/Table"

import { useTracker } from "src/hooks/use_tracker"

import { formattedCentsToDollars } from "src/utils/UnitConversion"
import { humanize } from "src/utils/string_helpers"

import { SALE_DETAIL_VIEW_PRESSED } from "../../amplitude_events"
import { SaleHistoryContext } from "./SaleHistoryContext"

const TableRow = ({ checkout }) => {
  const tracker = useTracker()
  const { marinaSlug } = useContext(SaleHistoryContext)
  const {
    id,
    boatName,
    createdAt,
    layout,
    total,
    saleCustomerName,
    saleEncodedId,
    saleStatus,
    soldBy,
  } = checkout

  const goToSaleDetail = () => {
    tracker.trackEvent(SALE_DETAIL_VIEW_PRESSED)
    window.location = `/manage/${marinaSlug}/point_of_sale/sales/${saleEncodedId}`
  }

  const checkoutTime = parseISO(createdAt)
  const createdAtDate = format(checkoutTime, "MM/dd/yyyy")
  const createdAtTime = format(checkoutTime, "pp")
  const contactName = saleCustomerName || GUEST_DISPLAY_NAME
  const status = saleStatus?.length ? humanize(saleStatus) : null

  return (
    <Table.Row onClick={goToSaleDetail} key={id} variant="parent">
      <Table.Cell title={`#${saleEncodedId}`} />
      <Table.Cell title={createdAtDate} subtitle={createdAtTime} />
      <Table.Cell title={contactName} subtitle={boatName} />
      <Table.Cell title={soldBy} />
      <Table.Cell>
        <span
          className={classNames(`overflow-hidden truncate`, {
            "text-gray-600": layout?.isDeleted,
          })}
        >
          {layout?.name} {layout?.isDeleted ? "(deleted)" : null}
        </span>
      </Table.Cell>
      <Table.Cell title={formattedCentsToDollars(total)} />
      <Table.Cell title={status} />
      <Table.Cell align="right">
        <Button onClick={goToSaleDetail} variant="tertiary">
          View
        </Button>
      </Table.Cell>
    </Table.Row>
  )
}

TableRow.propTypes = {
  checkout: PropTypes.shape({
    id: PropTypes.string.isRequired,
    boatName: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    layout: PropTypes.shape({
      name: PropTypes.string,
      isDeleted: PropTypes.bool,
    }),
    saleCustomerName: PropTypes.string,
    saleEncodedId: PropTypes.string.isRequired,
    saleStatus: PropTypes.string.isRequired,
    soldBy: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
}

export default TableRow
