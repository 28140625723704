import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import { Form, useForm } from "react-hook-form"
import { useMutation } from "react-query"

import { submitContract } from "../../../api/Contracts/SignContract"
import Button from "../../../components/Button"
import { useToast } from "../../../hooks/use_toast"
import { useTracker } from "../../../hooks/use_tracker"
import { SignContractWizardContext } from "./SignContractWizardContext"
import SignContractWizardNavigation from "./SignContractWizardNavigation"
import SignContractWizardStep from "./SignContractWizardStep"
import AgreementActions from "./Steps/AgreementActions"
import buildFormSubmissionData from "./buildFormSubmissionData"

const SignContractWizard = () => {
  const {
    contractChatUrl,
    customFieldDefinitions,
    dispatch,
    downloadPdfUrl,
    marina,
    mobileWebview,
    quote,
    savedPdfUrl,
    state,
    steps,
  } = useContext(SignContractWizardContext)

  const showToast = useToast()
  const tracker = useTracker()
  const form = useForm({ mode: "onBlur" })
  const { control, handleSubmit } = form

  const { declined, expired, currentStep } = state
  const declinedOrExpired = declined || expired

  const {
    mutate: submitContractMutation,
    isLoading,
    data,
    error,
  } = useMutation(["submitContract", quote.id], submitContract, {
    onSuccess: () => {
      window.location.href = `/contract_quotes/${quote.encodedId}/homeport_prompt`
    },
  })

  useEffect(() => {
    if (data !== undefined) {
      const {
        insuranceRequired,
        registrationRequired,
        contractSigningEnablements,
      } = quote

      tracker.trackEvent("contracts_v2:agreement_submitted", {
        contract_quote_id: quote.id,
        marina_id: marina.id,
        marina_name: marina.name,
        insurance: insuranceRequired,
        registration: registrationRequired,
        additional_info:
          contractSigningEnablements && contractSigningEnablements.length > 0,
      })

      showToast("Contract submitted successfully", { type: "success" })
    }
  }, [data, dispatch, marina.id, marina.name, quote, showToast, tracker])

  useEffect(() => {
    if (error !== null && error !== undefined) {
      showToast(error.message, { type: "error" })
    }
  }, [error, showToast])

  const handleSubmitCallback = async (data) => {
    const formData = buildFormSubmissionData(
      state,
      data,
      quote,
      customFieldDefinitions
    )

    submitContractMutation({ quoteId: quote.encodedId, formData })
  }

  const renderStep = () => {
    if (declined) {
      return <Declined />
    } else if (expired) {
      return <Expired marinaName={marina.name} />
    } else {
      return <SignContractWizardStep isSubmitting={isLoading} form={form} />
    }
  }

  return (
    <div className="m-auto mb-9 mt-4 grid max-w-[1150px] grid-cols-7 justify-center gap-8">
      <div className="col-span-7">
        <SignContractWizardNavigation isSubmitting={isLoading} />
      </div>
      <Form
        control={control}
        className="col-span-7 lg:col-span-5"
        onSubmit={handleSubmit(handleSubmitCallback)}
      >
        {renderStep()}
      </Form>
      <div className="col-span-7 flex flex-col space-y-4 lg:col-span-2">
        <div className="card flex flex-col">
          <h4 className="mt-0 font-semibold">Agreement</h4>
          <AgreementActions
            downloadPdfUrl={downloadPdfUrl}
            marina={marina}
            mobileWebview={mobileWebview}
            quoteId={quote.id}
            savedPdfUrl={savedPdfUrl}
            currentStep={steps[currentStep - 1].title}
          />
        </div>
        <div className="rounded-lg border border-gray-300 px-4 py-6">
          <h4 className="mx-0 mb-2 mt-0 font-semibold">Reach Out</h4>
          <ReachOut
            contractChatUrl={contractChatUrl}
            marinaId={marina.id}
            marinaName={marina.name}
            quoteId={quote.id}
          />
        </div>
        <MarinaNote note={quote.notes} />
        {!declinedOrExpired && <Decline />}
      </div>
    </div>
  )
}

export const ReachOut = ({
  contractChatUrl,
  marinaId,
  marinaName,
  quoteId,
}) => {
  const tracker = useTracker()

  const handleMessage = (_event) => {
    tracker.trackEvent("contracts_v2:marina_messaged", {
      contract_quote_id: quoteId,
      marina_id: marinaId,
      marina_name: marinaName,
    })
  }

  return (
    <>
      <p className="mb-4 font-semibold">
        Have a question about this agreement?
      </p>
      <a
        className="btn btn-tertiary w-full"
        onClick={handleMessage}
        href={contractChatUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        Message {marinaName}
      </a>
    </>
  )
}

ReachOut.propTypes = {
  marinaName: PropTypes.string.isRequired,
  marinaId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  quoteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  contractChatUrl: PropTypes.string.isRequired,
}

const MarinaNote = ({ note }) => {
  if (!note) return null

  return (
    <div className="rounded-lg border border-gray-300 px-4 py-6">
      <h4 className="mt-0 font-semibold">Marina Notes</h4>
      <span>{note}</span>
    </div>
  )
}

MarinaNote.propTypes = {
  note: PropTypes.string,
}

const Decline = () => {
  const { quote, dispatch, marina, state, steps } = useContext(
    SignContractWizardContext
  )
  const tracker = useTracker()
  const { encodedId } = quote

  const declineContract = async () => {
    await fetch(`/contract_quotes/${encodedId}/decline`, {
      method: "POST",
      headers: {
        "X-CSRF-Token":
          document.querySelector('meta[name="csrf-token"]')?.content ?? "",
      },
      redirect: "follow",
    })
  }

  const { mutateAsync: declineMutation, isLoading } = useMutation(
    ["declineContract", encodedId],
    declineContract
  )

  const handleDecline = (event) => {
    event.preventDefault()

    const confirmDecline = window.confirm(
      "Are you sure you want to decline this contract?"
    )

    if (!confirmDecline) return

    tracker.trackEvent("contracts_v2:agreement_declined", {
      contract_quote_id: quote.id,
      marina_id: marina.id,
      marina_name: marina.name,
      current_step: steps[state.currentStep - 1].title,
    })

    declineMutation({ quoteId: encodedId }).then(() => {
      dispatch({ type: "CONTRACT_DECLINED" })
    })
  }

  return (
    <div className="flex flex-col space-y-4 rounded-lg border border-gray-300 px-4 py-6">
      <span>
        If you no longer wish to complete this contract, you may decline the
        agreement below.
      </span>
      <Button
        variant="tertiary"
        onClick={handleDecline}
        fullWidth={true}
        isLoading={isLoading}
      >
        Decline Agreement
      </Button>
    </div>
  )
}

const Declined = () => {
  return (
    <div className="rounded-lg border border-gray-300 p-6">
      <div className="flex items-center space-x-2">
        <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-red-500 text-white">
          <i className="icon icon-close-mdc" />
        </div>
        <h4 className="m-0 font-semibold">This agreement has been declined</h4>
      </div>
    </div>
  )
}

const Expired = ({ marinaName }) => {
  return (
    <div className="rounded-lg border border-gray-300 p-6">
      <div className="flex items-center space-x-2">
        <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-red-500 text-white">
          <i className="icon icon-close-mdc" />
        </div>
        <h4 className="m-0 font-semibold">
          This agreement is voided and cannot be signed. Please contact{" "}
          {marinaName} for details.
        </h4>
      </div>
    </div>
  )
}

Expired.propTypes = {
  marinaName: PropTypes.string.isRequired,
}

export default SignContractWizard
