import { mutateApi, queryApi } from "src/utils/api/ApiMethods"

export const removeFavorite = (marinaSlug) => {
  return mutateApi({
    url: "/account/favorites",
    method: "DELETE",
    data: { marina_id: marinaSlug },
  })
}

export const getFavorites = () => {
  return queryApi("/account/favorites", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
}
