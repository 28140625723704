import classNames from "classnames"
import React, { useContext } from "react"
import { Controller, useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { ContractsFormContext } from "../ContractsFormContext"
import PageActions from "../wizard/PageActions"

const getRegistrationText = (registrationRequirement) => {
  if (registrationRequirement === "info") {
    return "Registration information (registration number and expiration date) will be required to sign this contract."
  } else if (registrationRequirement === "info_and_upload") {
    return "Registration information (registration number and expiration date) and proof of registration document upload will be required to sign this contract."
  } else {
    return "No registration information required to sign this contract."
  }
}

const getInsuranceText = (insuranceRequirement) => {
  if (insuranceRequirement === "info") {
    return "Insurance information (company name, policy number, and expiration date) will be required to sign this contract."
  } else if (insuranceRequirement === "info_and_upload") {
    return "Insurance information (company name, policy number, and expiration date) and proof of insurance document upload will be required to sign this contract."
  } else {
    return "No insurance information required to sign this contract."
  }
}

const AdditionalSettings = () => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const { documentRequirements, customFieldDefinitions } =
    useContext(ContractsFormContext)

  const registrationRequirement = watch("registrationRequirement")
  const insuranceRequirement = watch("insuranceRequirement")
  const selectedCustomFields = watch("selectedCustomFields")

  const handleToggleCustomField = (id) => {
    if (
      selectedCustomFields.map((a) => a.customFieldDefinitionId).includes(id)
    ) {
      return selectedCustomFields.filter(
        (a) => a.customFieldDefinitionId !== id
      )
    }
    return [
      ...selectedCustomFields,
      { customFieldDefinitionId: id, required: false },
    ]
  }

  const handleToggleRequirement = (selectedField, requirement) => {
    const updatedField = {
      ...selectedField,
      required: requirement === "required",
    }
    const index = selectedCustomFields.findIndex(
      (f) => f.customFieldDefinitionId === selectedField.customFieldDefinitionId
    )
    selectedCustomFields.splice(index, 1, updatedField)
    return selectedCustomFields
  }

  return (
    <>
      <div>
        <Form.Label htmlFor="registration-requirement" required>
          Require Registration
        </Form.Label>
        <Form.Select
          id="registration-requirement"
          {...register("registrationRequirement")}
        >
          {documentRequirements.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </Form.Select>
        <p className="text-muted">
          {getRegistrationText(registrationRequirement)}
        </p>
      </div>
      <div className="mb-2 border-b">
        <Form.Label htmlFor="insurance-requirement" required>
          Require Insurance
        </Form.Label>
        <Form.Select
          id="insurance-requirement"
          {...register("insuranceRequirement")}
        >
          {documentRequirements.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </Form.Select>
        <p className="text-muted">{getInsuranceText(insuranceRequirement)}</p>
      </div>
      <div>
        {/* https://wanderlustgroup.atlassian.net/browse/TB-899 */}
        <label
          className="mb-0 pb-2 pr-2 font-semibold"
          data-container="body"
          data-toggle="popover"
          data-placement="right"
          data-content="Check the box for each question you would like to be included on the contract signing page. Also, select if you would like the question to be required or optional."
        >
          Custom Fields
          <i className="icon icon-info text-muted ml-3" />
        </label>
        <div className="mb-2 flex max-h-64 flex-col gap-3 overflow-auto rounded border p-2 px-4">
          <Controller
            name="selectedCustomFields"
            render={({ field: { onChange, value, ref } }) => {
              return (
                <>
                  {customFieldDefinitions.map((field) => {
                    const selectedField = selectedCustomFields.find(
                      (f) => f.customFieldDefinitionId === field.id
                    )
                    return (
                      <div key={field.key} className="flex justify-between">
                        <Form.Checkbox
                          ref={ref}
                          name={field.key}
                          checked={value
                            .map((a) => a.customFieldDefinitionId)
                            .includes(field.id)}
                          onChange={() =>
                            onChange(handleToggleCustomField(field.id))
                          }
                          label={field.title}
                        />
                        {/* https://wanderlustgroup.atlassian.net/browse/TB-900 */}
                        {selectedField && (
                          <div className="w-28">
                            <select
                              className={classNames(
                                "badge mb-0 p-0.5 outline-none",
                                {
                                  "badge-blue": selectedField.required,
                                  "badge-teal": !selectedField.required,
                                }
                              )}
                              name={field.id}
                              value={
                                selectedField.required ? "required" : "optional"
                              }
                              onChange={(e) => {
                                onChange(
                                  handleToggleRequirement(
                                    selectedField,
                                    e.target.value
                                  )
                                )
                              }}
                            >
                              <option value="required">Required</option>
                              <option value="optional">Optional</option>
                            </select>
                          </div>
                        )}
                      </div>
                    )
                  })}
                </>
              )
            }}
          />
        </div>
      </div>
      <div>
        <label
          className="mb-0 pb-2 pr-2 font-semibold"
          data-container="body"
          data-toggle="popover"
          data-placement="right"
          htmlFor="notes"
          data-content="These notes will be included on the first page of the contract. Use this field to include additional information that the boater must know before signing."
        >
          Contract Note
          <i className="icon icon-info text-muted ml-3" />
        </label>
        <Form.Textarea
          id="note"
          {...register("notes")}
          hasErrors={!!errors?.notes}
        />
      </div>
      <PageActions />
    </>
  )
}

export default AdditionalSettings
