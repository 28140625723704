import {
  dollarsToCents,
  dollarsToHundredthsOfCents,
  pricePerUnitToDollars,
  toTaxPercent,
} from "src/main/Billing/Items/helpers"

export const createAvailabilityDefaults = (options) =>
  options.reduce((acc, type) => {
    const { value, checked } = type
    acc[value] = checked
    return acc
  }, {})

export const validatePrecision = (precision) => (number) => {
  const [, decimals] = String(number).split(".")

  if (decimals && decimals.length > precision) {
    if (precision === 0) {
      return `Please enter a whole number.`
    } else {
      return `Specify up to ${precision} decimal places.`
    }
  }
}

export const pricePrecisionForType = (type) => {
  switch (type) {
    case "fuel":
      return 4
    default:
      return 2
  }
}

export const defaultRecurrenceValue = (defaultRecurrence) =>
  defaultRecurrence === "does_not_repeat" ? null : defaultRecurrence

export const availabilitiesValue = (availabilities) =>
  Object.keys(availabilities).filter(
    (availability) => availabilities[availability]
  )

export const dollarsToPrecisionForType = ({ type, dollars }) => {
  if (!dollars) return null

  switch (type) {
    case "fuel":
      return dollarsToHundredthsOfCents(dollars)
    default:
      return dollarsToCents(dollars)
  }
}

export const activeChildItemsForProduct = (product) => {
  return product?.inventoryChildProducts?.filter(
    (childProduct) =>
      childProduct.deletedAt === null || childProduct.deleted_at === null
  )
}

export const getAvailabilitiesForType = ({
  type,
  availabilityOptions,
  electricType,
  isListedPumpPrice,
}) => {
  switch (type) {
    case "electric":
      return availabilityOptions.electric[electricType]
    case "fuel":
      return isListedPumpPrice
        ? availabilityOptions.fuel.listed_pump_price
        : availabilityOptions.fuel.default
    default:
      return availabilityOptions[type]
  }
}

export const defaultValuesForType = ({
  type,
  availabilityOptions,
  electricType = "non_metered",
}) => {
  const availabilitiesForType = getAvailabilitiesForType({
    type,
    availabilityOptions,
    electricType,
    isListedPumpPrice: false,
  })

  const availabilities = createAvailabilityDefaults(availabilitiesForType)

  switch (type) {
    case "standard":
      return {
        // intake
        type: "standard",
        name: null,
        sku: null,
        // price
        defaultPriceType: "per_quantity",
        defaultPrice: null,
        defaultTax: null,
        // category
        category: null,
        // inventory
        isInventory: false,
        lowQuantityBalanceThreshold: null,
        // details
        defaultRecurrence: "does_not_repeat",
        // availability
        availabilities,
      }
    case "boat_storage":
      return {
        // intake
        type: "boat_storage",
        name: null,
        // category
        category: null,
        // details
        spaceType: "Dock",
        // availability
        availabilities,
      }
    case "deposit":
      return {
        // intake
        type: "deposit",
        name: null,
        // price
        defaultPrice: null,
        defaultTax: null,
        // category
        category: null,
        // availability
        availabilities,
      }
    case "fee":
      return {
        // intake
        type: "fee",
        name: null,
        // price
        defaultPriceType: "per_quantity",
        defaultPrice: null,
        defaultTax: null,
        // category
        category: null,
        // availability
        availabilities,
      }
    case "fuel":
      return {
        // intake
        type: "fuel",
        name: null,
        sku: null,
        // price
        defaultPrice: null,
        defaultTax: null,
        // category
        category: null,
        // inventory
        isInventory: false,
        lowQuantityBalanceThreshold: null,
        inventoryProductId: null,
        // availability
        availabilities,
      }
    case "electric":
      if (electricType === "non_metered") {
        return {
          // intake
          type: "electric",
          name: null,
          electricType: "non_metered",
          // price
          defaultPriceType: "per_day",
          defaultPrice: null,
          defaultTax: null,
          // category
          category: null,
          // details
          defaultRecurrence: "does_not_repeat",
          // availability
          availabilities,
        }
      } else {
        return {
          // intake
          type: "electric",
          name: null,
          electricType: "metered",
          // price
          defaultPrice: null,
          defaultTax: null,
          // category
          category: null,
          // availability
          availabilities,
        }
      }
  }
}

export const defaultValuesForProduct = ({ product, availabilityOptions }) => {
  const availabilitiesForType = getAvailabilitiesForType({
    type: product.productType,
    availabilityOptions,
    electricType: product.electricType,
    isListedPumpPrice: product.isListedPumpPrice,
  })

  const productAvailabilities = product.availabilities.map((a) => a.key)
  const selectedAvailabilities = availabilitiesForType.map((availability) => {
    const checked = productAvailabilities.includes(availability.value)
    return { ...availability, checked }
  })
  const availabilities = createAvailabilityDefaults(selectedAvailabilities)

  switch (product.productType) {
    case "standard":
      return {
        // intake
        type: "standard",
        name: product.name,
        sku: product.sku,
        // price
        defaultPriceType: product.defaultPricingStructure,
        defaultPrice: product.pricePerUnit
          ? pricePerUnitToDollars({
              pricePerUnit: product.pricePerUnit,
              precision: product.pricePrecision,
            })
          : null,
        defaultTax: product.taxRate ? toTaxPercent(product.taxRate) : null,
        // category
        category: product.productCategory?.id,
        // inventory
        isInventory: product.isInventory,
        lowQuantityBalanceThreshold: product.lowQuantityBalanceThreshold,
        // details
        defaultRecurrence: product.defaultRecurrence ?? "does_not_repeat",
        // availability
        availabilities,
      }
    case "boat_storage":
      return {
        // intake
        type: "boat_storage",
        name: product.name,
        // category
        category: product.productCategory?.id,
        // details
        spaceType: product.spaceType,
        // availability
        availabilities,
      }
    case "deposit":
      return {
        // intake
        type: "deposit",
        name: product.name,
        // price
        defaultPrice: product.pricePerUnit
          ? pricePerUnitToDollars({
              pricePerUnit: product.pricePerUnit,
              precision: product.pricePrecision,
            })
          : null,
        defaultTax: product.taxRate ? toTaxPercent(product.taxRate) : null,
        // category
        category: product.productCategory?.id,
        // availability
        availabilities,
      }
    case "fee":
      return {
        // intake
        type: "fee",
        name: product.name,
        // price
        defaultPriceType: product.defaultPricingStructure,
        defaultPrice: product.pricePerUnit
          ? pricePerUnitToDollars({
              pricePerUnit: product.pricePerUnit,
              precision: product.pricePrecision,
            })
          : null,
        defaultTax: product.taxRate ? toTaxPercent(product.taxRate) : null,
        // category
        category: product.productCategory?.id,
        // availability
        availabilities,
      }
    case "fuel":
      return {
        // intake
        type: "fuel",
        name: product.name,
        sku: product.sku,
        // price
        defaultPrice: product.pricePerUnit
          ? pricePerUnitToDollars({
              pricePerUnit: product.pricePerUnit,
              precision: product.pricePrecision,
            })
          : null,
        defaultTax: product.taxRate ? toTaxPercent(product.taxRate) : null,
        // category
        category: product.productCategory?.id,
        // inventory
        isInventory: product.isInventory,
        lowQuantityBalanceThreshold: product.lowQuantityBalanceThreshold,
        inventoryProductId: product.inventoryProductId,
        // availability
        availabilities,
      }
    case "electric":
      if (product.electricType === "non_metered") {
        return {
          // intake
          type: "electric",
          name: product.name,
          electricType: "non_metered",
          // price
          defaultPriceType: product.defaultPricingStructure,
          defaultPrice: product.pricePerUnit
            ? pricePerUnitToDollars({
                pricePerUnit: product.pricePerUnit,
                precision: product.pricePrecision,
              })
            : null,
          defaultTax: product.taxRate ? toTaxPercent(product.taxRate) : null,
          // category
          category: product.productCategory?.id,
          // details
          defaultRecurrence: product.defaultRecurrence ?? "does_not_repeat",
          // availability
          availabilities,
        }
      } else {
        return {
          // intake
          type: "electric",
          name: product.name,
          electricType: "metered",
          // price
          defaultPrice: product.pricePerUnit
            ? pricePerUnitToDollars({
                pricePerUnit: product.pricePerUnit,
                precision: product.pricePrecision,
              })
            : null,
          defaultTax: product.taxRate ? toTaxPercent(product.taxRate) : null,
          // category
          category: product.productCategory?.id,
          // availability
          availabilities,
        }
      }
  }
}
