import { format } from "date-fns"
import PropTypes from "prop-types"
import React from "react"

import OverflowMenu from "src/components/OverflowMenu"

import { titlecase } from "src/utils/string_helpers"

import CruiseStopIcon from "./CruiseStopIcon"
import { dateToLoad } from "./DateTimeInput"
import displayDuration from "./displayDuration"
import typeMatch from "./typeMatch"

const CruiseStopItem = ({
  stop,
  previousStop,
  cruisePlanId,
  handleMoveStop,
}) => {
  const editLink = (stop) => {
    const type = typeMatch(stop.type, {
      reservation: () => "reservation",
      marina: () => "marina",
      location: () => "location",
      note: () => "note",
    })
    return `/account/cruise_plans/${cruisePlanId}/cruise_stops/${type}_stops/${stop.id}/edit`
  }

  const actions = (stop) => {
    return (
      <div className="flex items-center gap-2">
        <a href={editLink(stop)}>Edit</a>
        <a
          href={`/account/cruise_plans/${cruisePlanId}/cruise_stops/${stop.id}`}
          data-method="delete"
          data-confirm="Are you sure you want to delete this stop?"
        >
          Delete
        </a>
        <OverflowMenu>
          <OverflowMenu.Item onClick={() => handleMoveStop(stop.id, "up")}>
            Move Up
          </OverflowMenu.Item>
          <OverflowMenu.Item onClick={() => handleMoveStop(stop.id, "down")}>
            Move Down
          </OverflowMenu.Item>
        </OverflowMenu>
      </div>
    )
  }

  const renderPreviousStopDateErrors = () => {
    if (
      previousStop &&
      previousStop.endDate &&
      stop.startDate &&
      format(new Date(previousStop.endDate), "yyyy-MM-dd") !==
        format(new Date(stop.startDate), "yyyy-MM-dd")
    ) {
      return (
        <i
          className="icon icon-warning px-3 text-red-500"
          data-toggle="tooltip"
          data-placement="top"
          title="Possible date issue compared to previous stop end date"
        />
      )
    }
  }

  return (
    <tr>
      <td className="shrink">
        <CruiseStopIcon stopType={stop.type} />
      </td>
      <td className="px-4">
        {typeMatch(
          stop.type,
          {
            reservation: (stop) => stop.marina.name,
            marina: (stop) => stop.marina.name,
            location: (stop) => stop.name,
            note: (stop) => stop.title,
          },
          stop
        )}
      </td>
      <td className="px-4">
        {typeMatch(
          stop.type,
          {
            reservation: (stop) => stop.latitude,
            marina: (stop) => stop.latitude,
            location: (stop) => stop.latitude,
            note: () => null,
          },
          stop
        )}
      </td>
      <td className="px-4">
        {typeMatch(
          stop.type,
          {
            reservation: (stop) => stop.longitude,
            marina: (stop) => stop.longitude,
            location: (stop) => stop.longitude,
            note: () => null,
          },
          stop
        )}
      </td>
      <td className="px-4">
        {stop.startDate && format(new Date(stop.startDate), "MMM dd, yyyy")}
        {renderPreviousStopDateErrors()}
      </td>
      <td className="px-4">
        {stop.endDate && format(new Date(stop.endDate), "MMM dd, yyyy")}
      </td>
      <td className="px-4">
        {stop.startDate &&
          format(dateToLoad(stop.startDate), "HH:mm") !== "00:00" &&
          format(dateToLoad(stop.startDate), "hh:mm a")}
      </td>
      <td className="px-4">
        {stop.endDate &&
          format(dateToLoad(stop.endDate), "HH:mm") !== "00:00" &&
          format(dateToLoad(stop.endDate), "hh:mm a")}
      </td>
      <td className="px-4">{stop.nauticalMiles}</td>
      <td className="px-4">{stop.knots}</td>
      <td className="px-4">{displayDuration(stop.duration)}</td>
      <td className="px-4">{stop.note}</td>
      <td className="px-4">
        {stop.reservation && (
          <span className="mr-4 rounded bg-blue-100 px-2 py-0.5 font-bold text-blue-900">
            {titlecase(stop.reservation.status)}
          </span>
        )}
      </td>
      <td className="px-4">{actions(stop)}</td>
    </tr>
  )
}

CruiseStopItem.propTypes = {
  stop: PropTypes.shape({
    id: PropTypes.string.isRequired,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
    note: PropTypes.string,
    marina: PropTypes.shape({
      name: PropTypes.string,
    }),
    title: PropTypes.string,
    reservation: PropTypes.shape({
      status: PropTypes.string,
    }),
    nauticalMiles: PropTypes.string,
    knots: PropTypes.string,
    duration: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
  }).isRequired,
  previousStop: PropTypes.shape({
    endDate: PropTypes.string,
  }),
  cruisePlanId: PropTypes.string.isRequired,
  handleMoveStop: PropTypes.func.isRequired,
}

export default CruiseStopItem
