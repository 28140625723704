import PropTypes from "prop-types"
import React from "react"

import Button from "../../components/Button"

const ReservationStopSelector = ({ upcomingReservations, setReservation }) => {
  const onClick = () => {
    window.location.href = "/search"
  }

  const emptyState = () => {
    return (
      <div className="flex flex-col items-center gap-4 rounded border p-8">
        <i className="icon icon-power-boat -mb-4 text-5xl leading-none text-blue-700" />
        <span className="font-semibold">
          You don&apos;t have any upcoming reservations.
        </span>
        <Button variant="primary" onClick={onClick}>
          Explore Destinations
        </Button>
      </div>
    )
  }

  const fullState = () => {
    return (
      <div className="flex flex-col items-center gap-4 rounded border p-8">
        <i className="icon icon-power-boat -mb-4 text-5xl leading-none text-blue-700" />
        <span className="font-semibold">
          Don&apos;t see a reservation for your next stop?
        </span>
        <Button variant="primary" onClick={onClick}>
          Book a Reservation
        </Button>
      </div>
    )
  }

  return (
    <div>
      {upcomingReservations.length === 0 ? emptyState() : fullState()}
      {upcomingReservations.length > 0 && (
        <div className="mt-4 flex flex-col gap-4">
          {upcomingReservations.map((reservation) => {
            return (
              <div
                key={reservation.encodedId}
                onClick={() => setReservation(reservation)}
                className="flex cursor-pointer gap-4 rounded border p-4 hover:bg-gray-100"
              >
                <img
                  className="size-18 rounded"
                  src={reservation.marinaImageUrl}
                />
                <div>
                  <h2 className="m-0 text-lg font-semibold">
                    {reservation.marinaName}
                  </h2>
                  <div>{reservation.readableDateRange}</div>
                  <span className="mt-2">{reservation.readableBoatName}</span>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

ReservationStopSelector.propTypes = {
  upcomingReservations: PropTypes.arrayOf(
    PropTypes.shape({
      encodedId: PropTypes.string.isRequired,
      marinaImageUrl: PropTypes.string,
      marinaName: PropTypes.string,
      readableDateRange: PropTypes.string,
      readableBoatName: PropTypes.string,
    })
  ).isRequired,
  setReservation: PropTypes.func.isRequired,
}

export default ReservationStopSelector
