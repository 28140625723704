import PropTypes from "prop-types"
import React from "react"
import { Controller } from "react-hook-form"

import Form from "src/components/Form"

import { validateRecurringEndDate } from "../../validators"

export const RecurringEndDateField = ({
  errors,
  control,
  startDate,
  disabled,
}) => {
  return (
    <div data-testid="recurring-end-date">
      <Controller
        name="end_date"
        control={control}
        minDate={startDate}
        rules={{
          validate: (date) =>
            validateRecurringEndDate({
              end: date,
              start: startDate,
            }),
        }}
        render={({ field: { onChange, value } }) => (
          <Form.DatePicker
            id="end-date"
            renderCustomHeader={(props) => (
              <Form.DatePicker.QuickNavHeader {...props} />
            )}
            {...{ onChange, value }}
            hasErrors={Boolean(errors.end_date)}
            disabled={disabled}
          />
        )}
      />
      {errors.end_date && <Form.Error>{errors.end_date.message}</Form.Error>}
    </div>
  )
}

RecurringEndDateField.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  startDate: PropTypes.object,
  disabled: PropTypes.bool,
}

export default RecurringEndDateField
