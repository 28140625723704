import PropTypes from "prop-types"
import React, { useEffect, useMemo } from "react"
import { useInfiniteQuery } from "react-query"

import { queryReviews } from "src/api/Reviews"

import BackToTop from "./BackToTop"
import ReviewAggregation from "./ReviewAggregation"
import ReviewEmptyState from "./ReviewEmptyState"
import ReviewList from "./ReviewList"

const handleScroll = (hasNextPage, fetchNextPage) => {
  return async (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target.scrollingElement
    if (scrollHeight - scrollTop <= clientHeight) {
      if (hasNextPage) await fetchNextPage()
    }
  }
}

const ReviewContainer = ({ marinaSlug, aggregation }) => {
  const { data, isFetching, isError, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: ["reviews"],
      queryFn: (page) => queryReviews(marinaSlug, page.pageParam),
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.page >= lastPage.pageCount) return undefined
        return lastPage.page + 1
      },
    })

  useEffect(() => {
    const handleScrollFn = handleScroll(hasNextPage, fetchNextPage)
    document.addEventListener("scroll", handleScrollFn)
    return () => {
      document.removeEventListener("scroll", handleScrollFn)
    }
  }, [hasNextPage, fetchNextPage])

  const reviews = useMemo(() => {
    if (data === undefined) return []
    return data.pages.flatMap((page) => page.items)
  }, [data])

  if (isFetching) return <div className="container mx-auto">Loading...</div>
  if (isError)
    return <div className="container mx-auto">Error Loading Reviews</div>

  if (reviews.length > 0)
    return (
      <div className="container mx-auto md:flex md:flex-row">
        <div className="w-full md:w-1/4">
          <ReviewAggregation data={aggregation} slug={marinaSlug} />
        </div>
        <div className="w-full md:w-3/4">
          <ReviewList reviews={reviews} />
        </div>
        <BackToTop />
      </div>
    )
  else return <ReviewEmptyState slug={marinaSlug} />
}

ReviewContainer.propTypes = {
  marinaSlug: PropTypes.string.isRequired,
  aggregation: PropTypes.object.isRequired,
}

export default ReviewContainer
