import React, { useContext } from "react"

import Heading from "src/components/Heading"

import { SaleDetailsContext } from "../SaleDetailsContext"
import SaleCheckout from "./SaleCheckout"

const SaleCheckouts = () => {
  const { sale } = useContext(SaleDetailsContext)

  return (
    <div className="mb-12">
      <Heading.SubSectionHeader>Items</Heading.SubSectionHeader>
      <div className="mt-4">
        {sale &&
          sale.checkouts.map((checkout, index) => (
            <div key={`checkout-${checkout.id}`}>
              <SaleCheckout checkout={checkout} index={index} />
            </div>
          ))}
      </div>
    </div>
  )
}

export default SaleCheckouts
