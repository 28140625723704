import PropTypes from "prop-types"
import React from "react"
import { useQuery } from "react-query"
import FavoriteCard from "src/main/Account/Favorites/FavoriteCard"

import { getFavorites } from "src/api/Account/Favorites"

import { useTracker } from "src/hooks/use_tracker"

const Favorites = ({ favorites }) => {
  const tracker = useTracker()
  const { data: favoritesData } = useQuery("favorites", getFavorites, {
    initialData: favorites,
    refetchOnMount: false,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const handleExploreClick = () => {
    tracker.trackEvent("favorites:start_exploring")
  }

  const renderEmptyState = () => (
    <div className="py-8 text-center">
      <p className="mb-4 text-lg font-bold">It&apos;s lonely in here!</p>
      <p className="mb-4">
        Click the heart icon to favorite marinas for quick booking later.
      </p>
      <a
        className="btn btn-primary mb-3"
        href="/search"
        onClick={handleExploreClick}
      >
        Start Exploring
      </a>
    </div>
  )

  const renderContent = () => {
    if (favoritesData.length === 0) {
      return renderEmptyState()
    }

    return (
      <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {favoritesData.map((favorite) => (
          <FavoriteCard favorite={favorite} key={favorite.id} />
        ))}
      </div>
    )
  }

  return (
    <div className="pb-12">
      <h1 className="text-2xl font-bold">Favorites</h1>
      {renderContent()}
    </div>
  )
}

Favorites.propTypes = {
  favorites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      marinaLocation: PropTypes.string,
      marinaName: PropTypes.string,
      marinaImageUrl: PropTypes.string,
      marinaRating: PropTypes.number,
      marinaReviewCount: PropTypes.number,
      marinaSlug: PropTypes.string,
      viewUrl: PropTypes.string,
    }).isRequired
  ),
}

export default Favorites
