import { compareAsc, parseISO } from "date-fns"

export const getRequestPaymentMethodId = (paymentMethodId) => {
  switch (paymentMethodId) {
    case "manual":
    case "":
    case undefined:
      return null
    default:
      return Number(paymentMethodId)
  }
}

export const getFirstOfNextMonth = () => {
  const today = new Date()
  const nextMonth = today.getMonth() + 1
  const year = nextMonth === 12 ? today.getFullYear() + 1 : today.getFullYear()
  return new Date(year, nextMonth % 12, 1)
}

export const groupByRecurringProductSaleId = (obj, item) => {
  const recurringProductSaleId =
    item.product_sale.recurring_product_sale?.id || null

  obj[recurringProductSaleId] = obj[recurringProductSaleId] || []
  obj[recurringProductSaleId].push(item)

  return obj
}

export const hasDisputedInvoice = (invoices) => {
  return invoices?.some((invoice) => isDisputed(invoice))
}

export const isDisputed = (invoice) => {
  return invoice?.display_status === "Disputed"
}

// https://stackoverflow.com/a/31732581
// TLDR: JS handles dates weirdly, if you use dash-separated
// dates you can end up a day off VS using slash separations
export const parseDateValue = (dateString) => {
  return parseISO(dateString, "yyyy-MM-dd", new Date())
}

export const isLostDispute = (invoiceTxn) => {
  return invoiceTxn?.txn?.stripe_payment?.status === "twg_dispute_lost"
}

export const isNonSucceededStripePayment = (invoiceTxn) => {
  return (
    invoiceTxn?.txn?.stripe_payment &&
    invoiceTxn.txn.stripe_payment.status !== "succeeded"
  )
}

const getProductSaleOrReturn = (i) => {
  if ("txn" in i) {
    return i.txn?.product_sale ?? i.txn?.product_return
  }
  return i.product_sale ?? i?.product_return
}

export const sortItems = (items = []) => {
  if (!items?.length) return

  const itemsToSort = [...items]
  const [reservationSale] = itemsToSort.splice(
    items.findIndex((i) => getProductSaleOrReturn(i).reservation_sale),
    1
  )

  return [reservationSale, ...itemsToSort.sort(sortByServiceStartDateAndName)]
}

export const sortRecurringItems = (items = []) => {
  if (!items?.length) return

  return items.sort(sortByServiceStartDateAndName)
}

const sortByServiceStartDateAndName = (a, b) => {
  const productSaleA = getProductSaleOrReturn(a)
  const productSaleB = getProductSaleOrReturn(b)

  const parsedDateA = parseDateValue(
    productSaleA?.service_start_date ??
      productSaleA?.product_sale_service_start_date
  )
  const parsedDateB = parseDateValue(
    productSaleB?.service_start_date ??
      productSaleB?.product_sale_service_start_date
  )
  const dateCompare = compareAsc(parsedDateA, parsedDateB)
  if (dateCompare === 0) {
    return (
      productSaleA?.name ?? productSaleA?.product_sale_name
    ).localeCompare(productSaleB?.name ?? productSaleB?.product_sale_name)
  }
  return dateCompare
}

export const getDatePortionOfISODate = (isoDateString) => {
  return isoDateString.split("T")[0]
}
