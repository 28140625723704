import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { useMutation, useQuery } from "react-query"

import Loader from "src/components/Loader"
import Table from "src/components/Table/index"

import { queryGeneralLedgerCodes } from "src/api/Accounting"

import { useToast } from "src/hooks/use_toast"

import { updateAllGeneralLedgerCodes } from "../../api/Accounting"
import { AccountingContext } from "./AccountingView"
import ManageTableRow from "./ManageTableRow"

const ManageTableHeader = ({ generalLedgerCodes }) => {
  const { setValue, getValues } = useFormContext()

  const { marinaSlug } = useContext(AccountingContext)
  const showToast = useToast()

  const { mutate, data } = useMutation(
    (data) => updateAllGeneralLedgerCodes(marinaSlug, data),
    {
      onError: (error) => {
        showToast(error.toString(), { type: "error", duration: 5 })
      },
    }
  )

  useEffect(() => {
    if (data !== undefined) {
      data.forEach(({ id, hidden }) => {
        setValue(`general_ledger_codes.gl-${id}`, !hidden)
      })
    }
  }, [data, setValue])

  const showAll = () => {
    const codes = getValues("general_ledger_codes")

    Object.keys(codes).forEach((key) => {
      setValue(`general_ledger_codes.${key}`, true)
    })
    mutate({ general_ledger_code: { hidden: false } })
  }

  const hideUnmapped = () => {
    const codes = getValues("general_ledger_codes")

    Object.keys(codes).forEach((key) => {
      // eslint-disable-next-line no-unused-vars
      const [_, id] = key.split("-")
      const { mapped } = generalLedgerCodes[id]

      if (!mapped) {
        setValue(`general_ledger_codes.${key}`, false)
      }
    })

    mutate({ general_ledger_code: { hidden: true } })
  }

  return (
    <Table.Head>
      <Table.Head.Row>
        <Table.Head.Cell>Account</Table.Head.Cell>
        <Table.Head.Cell align="right" columnWidth="300px">
          <span className="flex items-center justify-end pt-2">
            <button onClick={showAll} className="mr-1 bg-transparent pb-2">
              Show all
            </button>
            <span className="pb-2">•</span>
            <button
              onClick={hideUnmapped}
              className="ml-1 mr-3 bg-transparent pb-2"
            >
              Hide unmapped
            </button>
          </span>
        </Table.Head.Cell>
      </Table.Head.Row>
    </Table.Head>
  )
}

const ManageTable = () => {
  const methods = useForm()
  const { marinaSlug } = useContext(AccountingContext)
  const { data, error, isError, isLoading } = useQuery(
    ["accounting", "manage-tab"],
    () =>
      queryGeneralLedgerCodes({
        marinaSlug: marinaSlug,
      }),
    { retry: false }
  )

  if (isLoading) {
    return (
      <div className="bg-white">
        <Loader name="chart of accounts" />
      </div>
    )
  } else if (isError) {
    return (
      <div className="text-muted bg-white p-5 text-center">
        <h3 className="mb-5">{error.message}</h3>
      </div>
    )
  } else if (data) {
    if (data.length === 0) {
      return (
        <div className="text-muted bg-white p-5 text-center">
          <h3 className="mb-5">No accounts to display</h3>
        </div>
      )
    } else {
      const generalLedgerCodes = data.reduce(
        (generalLedgerCodesById, generalLedgerCode) => {
          generalLedgerCodesById[generalLedgerCode.id] = generalLedgerCode
          return generalLedgerCodesById
        },
        {}
      )
      return (
        <FormProvider {...methods}>
          <div
            data-testid="manage-general-ledger-codes-table"
            className="h-full w-full pb-8"
          >
            <Table fullHeight>
              <ManageTableHeader generalLedgerCodes={generalLedgerCodes} />
              <Table.Body>
                {data &&
                  data.map((generalLedgerCode) => (
                    <ManageTableRow
                      generalLedgerCode={generalLedgerCode}
                      key={generalLedgerCode.id}
                    />
                  ))}
              </Table.Body>
            </Table>
          </div>
        </FormProvider>
      )
    }
  }
}

ManageTableHeader.propTypes = {
  generalLedgerCodes: PropTypes.object.isRequired,
}

export default ManageTable
